import {SyntheticEvent, useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMutation} from '@apollo/client';
import {
    AddBookmarkDocument,
    RemoveBookmarkDocument,
    UserDocument,
} from 'gql/generated';
import {ToastType, useToastNotification} from 'state/toastNotification';
import {useUser} from 'state/user';
import {handleError} from 'utils/functions';

export const useBookmarks = (venueId: string) => {
    const {t} = useTranslation();

    const user = useUser();
    const {bookmarkedVenues, id} = user || {};

    const [showAuthModal, setShowAuthModal] = useState(false);

    const {showToast} = useToastNotification();

    const onClose = useCallback(() => {
        setShowAuthModal(false);
    }, []);

    const isBookmarked = useMemo(
        () =>
            bookmarkedVenues?.find(
                (bookmarkedVenue) => venueId === bookmarkedVenue.id
            ),
        [bookmarkedVenues, venueId]
    );

    const [addBookmark, addBookmarkStatus] = useMutation(AddBookmarkDocument, {
        awaitRefetchQueries: true,
        refetchQueries: [UserDocument],
    });
    const [removeBookmark, removeBookmarkStatus] = useMutation(
        RemoveBookmarkDocument,
        {
            awaitRefetchQueries: true,
            refetchQueries: [UserDocument],
        }
    );

    const isLoading = addBookmarkStatus.loading || removeBookmarkStatus.loading;

    const onClick = async (event: SyntheticEvent) => {
        event.preventDefault();

        if (!id) {
            setShowAuthModal(true);
        } else if (isBookmarked) {
            await removeBookmark({variables: {venueId}})
                .then(() => {
                    showToast({
                        message: t('bookmarks.removed'),
                        type: ToastType.Success,
                    });
                })
                .catch(handleError);
        } else {
            await addBookmark({variables: {venueId}})
                .then(() => {
                    showToast({
                        message: t('bookmarks.successfullyAdded'),
                        type: ToastType.Success,
                    });
                })
                .catch(handleError);
        }
    };

    return {
        isBookmarked,
        isLoading,
        onClick,
        onClose,
        showAuthModal,
    };
};
