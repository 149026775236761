import {FC} from 'react';
import {Outlet} from 'react-router-dom';
import AuthenticatedPage from 'components/AuthenticatedPage';
import Footer from 'components/Footer';
import Header, {HeaderProps} from 'components/Header';
import {SiteNotificationBanner} from 'components/SiteNotificationBanner';
import fontAwesome from 'icons';
import {isProduction, SEMVER_COMMIT_HASH} from 'utils/environment';
import loadStyles from 'utils/styles';

const VERSION = isProduction() ? undefined : SEMVER_COMMIT_HASH;
loadStyles();
fontAwesome();

type PageLayoutProps = {
    hideFooter?: boolean;
    showBanner?: boolean;
} & HeaderProps;

const PageLayout: FC<PageLayoutProps> = ({
    hideFooter,
    isAuthenticatedPage,
    isSessionDisabled,
    showBanner = false,
}) => {
    return (
        <div className="flex h-min min-h-full w-full flex-col justify-between">
            <Header
                isAuthenticatedPage={isAuthenticatedPage}
                isSessionDisabled={isSessionDisabled}
            />
            {/* TODO: Uncomment in case you want to show a banner below the header */}
            {showBanner && <SiteNotificationBanner />}
            <main className="mb-auto h-fit">
                {isAuthenticatedPage ? <AuthenticatedPage /> : <Outlet />}
            </main>
            {!hideFooter && <Footer version={VERSION} />}
        </div>
    );
};

export default PageLayout;
