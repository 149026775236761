import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocale} from 'state/locale';

export const SiteNotificationBanner: FC = () => {
    const {t} = useTranslation();
    const locale = useLocale();

    return (
        <a
            className="group block w-full bg-gold-200 py-4"
            href={
                locale === 'en'
                    ? 'https://pocket-concierge.my.site.com/CustomerHelp/s/article/user-the-New-Year-s-holiday?language=en_US'
                    : 'https://pocket-concierge.my.site.com/CustomerHelp/s/article/user-the-New-Year-s-holiday?language=ja'
            }
            rel="noreferrer"
            target="_blank"
        >
            <div className="site-container text-center text-sm text-white group-hover:underline">
                {t('bannerNotice')}
            </div>
        </a>
    );
};
