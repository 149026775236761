import {gql} from '@apollo/client';
import * as Apollo from '@apollo/client';

export type Maybe<T> = T | null;

export type InputMaybe<T> = Maybe<T>;

export type Exact<T extends {[key: string]: unknown}> = {[K in keyof T]: T[K]};

export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]?: Maybe<T[SubKey]>;
};

export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    /** An ISO 8601-encoded date */
    ISO8601Date: any;
    /** An ISO 8601-encoded datetime */
    ISO8601DateTime: any;
};

/** A reason specified by a user for deleting their account */
export enum AccountDeletionReason {
    /** Poor customer service */
    BadResponse = 'BAD_RESPONSE',
    /** The service is hard to use */
    HardToUseService = 'HARD_TO_USE_SERVICE',
    /** The restaurants are too expensive */
    LifestyleMismatch = 'LIFESTYLE_MISMATCH',
    /** Not interested in any of the restaurants */
    NoAttractiveRestaurants = 'NO_ATTRACTIVE_RESTAURANTS',
    /** No discount campaigns available */
    NoCampaign = 'NO_CAMPAIGN',
    /** Other */
    Other = 'OTHER',
}

/** Autogenerated input type of AddCard */
export type AddCardInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: InputMaybe<Scalars['String']>;
    defaultCard?: InputMaybe<Scalars['Boolean']>;
    stripeToken: Scalars['String'];
};

/** Contains information about an address */
export type Address = {
    __typename?: 'Address';
    city: City;
    prefecture: Prefecture;
    street?: Maybe<Scalars['String']>;
    town: Town;
};

/** An eligibility provided through American Express */
export enum AmexBenefitsEligibility {
    /** Any American Express card (incl. network cards) */
    AnyAmexCard = 'ANY_AMEX_CARD',
    /** An American Express ANA Gold Card issued in Japan */
    JapanAnaGold = 'JAPAN_ANA_GOLD',
    /** An American Express ANA Premium Card issued in Japan */
    JapanAnaPremium = 'JAPAN_ANA_PREMIUM',
    /** An American Express Centurion Card issued in Japan */
    JapanCenturion = 'JAPAN_CENTURION',
    /** An American Express Gold Card issued in Japan */
    JapanGold = 'JAPAN_GOLD',
    /** An American Express Gold Preferred Card issued in Japan */
    JapanGoldPreferred = 'JAPAN_GOLD_PREFERRED',
    /** An American Express Green Card issued in Japan */
    JapanGreen = 'JAPAN_GREEN',
    /** An American Express Platinum Card issued in Japan */
    JapanPlatinum = 'JAPAN_PLATINUM',
    /** An American Express SBS Platinum Card issued in Japan */
    JapanSbsPlatinum = 'JAPAN_SBS_PLATINUM',
}

/** Contains information about an area */
export type Area = {
    __typename?: 'Area';
    children?: Maybe<Array<Area>>;
    id: Scalars['ID'];
    name: Scalars['String'];
    parent?: Maybe<Area>;
    priority: Scalars['Int'];
    venues: Array<Venue>;
};

/** Contains data for an availability calendar */
export type AvailabilityCalendar = {
    __typename?: 'AvailabilityCalendar';
    reservationDates: Array<Scalars['ISO8601Date']>;
    waitlistDates: Array<Scalars['ISO8601Date']>;
};

/** Union of ReservableAvailabilityType & WaitlistableAvailabilityType */
export type AvailabilityUnion =
    | ReservableAvailability
    | WaitlistableAvailability;

/** Contains information about a guest where the reservation is made on their behalf */
export type BookedOnBehalfOfGuest = {
    __typename?: 'BookedOnBehalfOfGuest';
    email: Scalars['String'];
    firstName: Scalars['String'];
    firstNameKana?: Maybe<Scalars['String']>;
    lastName: Scalars['String'];
    lastNameKana?: Maybe<Scalars['String']>;
    phoneNumber: PhoneNumber;
};

/** Autogenerated input type of BookmarkAddVenue */
export type BookmarkAddVenueInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: InputMaybe<Scalars['String']>;
    venueId: Scalars['ID'];
};

/** Autogenerated return type of BookmarkAddVenue. */
export type BookmarkAddVenuePayload = {
    __typename?: 'BookmarkAddVenuePayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of BookmarkRemoveVenue */
export type BookmarkRemoveVenueInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: InputMaybe<Scalars['String']>;
    venueId: Scalars['ID'];
};

/** Autogenerated return type of BookmarkRemoveVenue. */
export type BookmarkRemoveVenuePayload = {
    __typename?: 'BookmarkRemoveVenuePayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
};

export type CancellationInfo = {
    __typename?: 'CancellationInfo';
    amount: Scalars['Int'];
    percentage: Scalars['Int'];
};

/** Represents a cash payment. Only used through PaymentMethod. */
export type CashPayment = {
    __typename?: 'CashPayment';
    _?: Maybe<Scalars['Boolean']>;
};

/** Contains information about a city */
export type City = {
    __typename?: 'City';
    id: Scalars['ID'];
    name: Scalars['String'];
    prefecture: Prefecture;
    town: Town;
    venues: Array<Venue>;
};

export type CollectionMetadata = {
    __typename?: 'CollectionMetadata';
    currentPage: Scalars['Int'];
    limitValue: Scalars['Int'];
    totalCount: Scalars['Int'];
    totalPages: Scalars['Int'];
};

/** Autogenerated input type of Confirm */
export type ConfirmInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: InputMaybe<Scalars['String']>;
    /** StripePaymentMethod id */
    id: Scalars['ID'];
};

/** Contains a course */
export type Course = {
    __typename?: 'Course';
    costPerGuest: Scalars['Int'];
    fixedPrice?: Maybe<Scalars['Int']>;
    fixedTitle?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    name: Scalars['String'];
    serviceType: ServiceType;
    summary?: Maybe<Scalars['String']>;
    supplementaryInformation?: Maybe<Scalars['String']>;
};

export type Credential = {
    __typename?: 'Credential';
    accessToken: Scalars['String'];
    client: Scalars['String'];
    expiry: Scalars['Int'];
    tokenType: Scalars['String'];
    uid: Scalars['String'];
};

/** Contains a credit card */
export type CreditCard = {
    __typename?: 'CreditCard';
    amexBenefitsEligibility: Array<AmexBenefitsEligibility>;
    cardBrand: CreditCardBrand;
    cardExpiryMonth: Scalars['Int'];
    cardExpiryYear: Scalars['Int'];
    /** returns true if card is currently in use on an active reservation */
    cardInUse: Scalars['Boolean'];
    cardLast4: Scalars['String'];
    /** returns true if the latest intent is not suceeded status */
    confirmationNeeded: Scalars['Boolean'];
    defaultCard: Scalars['Boolean'];
    id: Scalars['ID'];
    stripeSetupIntentId?: Maybe<Scalars['String']>;
    stripeSetupIntentStatus?: Maybe<SetupIntentStatus>;
    stripeToken: Scalars['String'];
};

/** A credit card brand */
export enum CreditCardBrand {
    /** American Express */
    Amex = 'AMEX',
    /** Diners Club */
    DinersClub = 'DINERS_CLUB',
    /** Discover */
    Discover = 'DISCOVER',
    /** JCB */
    Jcb = 'JCB',
    /** MasterCard */
    Mastercard = 'MASTERCARD',
    /** UnionPay */
    Unionpay = 'UNIONPAY',
    /** VISA */
    Visa = 'VISA',
}

/** Contains a credit card with the next action that should be taken */
export type CreditCardWithClientSecret = {
    __typename?: 'CreditCardWithClientSecret';
    /** the client_secret as provided by Stripe; null if already successful */
    clientSecret?: Maybe<Scalars['String']>;
    creditCard: CreditCard;
};

/** Contains information about a cuisine */
export type Cuisine = {
    __typename?: 'Cuisine';
    id: Scalars['ID'];
    name: Scalars['String'];
};

/** User setting regarding receipt of featured mail notifications */
export enum FeaturedMailNotificationEnum {
    Accept = 'ACCEPT',
    Deny = 'DENY',
}

/** Contains a frequently asked question and answer */
export type FrequentlyAskedQuestion = {
    __typename?: 'FrequentlyAskedQuestion';
    answer: Scalars['String'];
    question: Scalars['String'];
};

/** Gender */
export enum Gender {
    /** Female gender */
    Female = 'FEMALE',
    /** Male gender */
    Male = 'MALE',
    /** Gender not set */
    Unknown = 'UNKNOWN',
}

export enum LanguageEnum {
    English = 'ENGLISH',
    Japanese = 'JAPANESE',
}

export type MarketingContent = {
    __typename?: 'MarketingContent';
    contentType: MarketingContentTypeEnum;
    external: Scalars['Boolean'];
    id: Scalars['ID'];
    imageFilename?: Maybe<Scalars['String']>;
    imageUrl: Scalars['String'];
    language: LanguageEnum;
    link: Scalars['String'];
    restaurant?: Maybe<Venue>;
};

/** Type of marketing content */
export enum MarketingContentTypeEnum {
    EditorsPick = 'editors_pick',
    FeaturedVenue = 'featured_venue',
    Home = 'home',
    Navigator = 'navigator',
    Venue = 'venue',
    VenueSearchMajor = 'venue_search_major',
    VenueSearchMinor = 'venue_search_minor',
}

export type Mutation = {
    __typename?: 'Mutation';
    addCard: CreditCardWithClientSecret;
    bookmarkAddVenue?: Maybe<BookmarkAddVenuePayload>;
    bookmarkRemoveVenue?: Maybe<BookmarkRemoveVenuePayload>;
    /** updates the card details with the setup intent status, returning an error if the card is invalid */
    confirmSetupIntent: CreditCard;
    paymentMethodRemoveCreditCard?: Maybe<PaymentMethodRemoveCreditCardPayload>;
    requestSetupIntent: CreditCardWithClientSecret;
    reservationCancel?: Maybe<ReservationCancelPayload>;
    reservationCreate?: Maybe<ReservationCreatePayload>;
    reservationSendMessage?: Maybe<ReservationSendMessagePayload>;
    reservationSubmitQuestionnaire?: Maybe<ReservationSubmitQuestionnairePayload>;
    setDefaultCard: CreditCard;
    userConfirmRegistrationWithToken?: Maybe<UserConfirmRegistrationWithTokenPayload>;
    userDeleteAccount?: Maybe<UserDeleteAccountPayload>;
    userLogin?: Maybe<UserLoginPayload>;
    userLogout?: Maybe<UserLogoutPayload>;
    userResendConfirmationWithToken?: Maybe<UserResendConfirmationWithTokenPayload>;
    userSendPasswordResetWithToken?: Maybe<UserSendPasswordResetWithTokenPayload>;
    userSignUp?: Maybe<UserSignUpPayload>;
    userUnlockAccount?: Maybe<UnlockAccountPayload>;
    userUpdateEmail?: Maybe<UserUpdateEmailPayload>;
    userUpdatePassword?: Maybe<UserUpdatePasswordPayload>;
    userUpdatePasswordWithToken?: Maybe<UpdatePasswordWithTokenPayload>;
    userUpdateProfile?: Maybe<UserUpdateProfilePayload>;
};

export type MutationAddCardArgs = {
    input: AddCardInput;
};

export type MutationBookmarkAddVenueArgs = {
    input: BookmarkAddVenueInput;
};

export type MutationBookmarkRemoveVenueArgs = {
    input: BookmarkRemoveVenueInput;
};

export type MutationConfirmSetupIntentArgs = {
    input: ConfirmInput;
};

export type MutationPaymentMethodRemoveCreditCardArgs = {
    input: PaymentMethodRemoveCreditCardInput;
};

export type MutationRequestSetupIntentArgs = {
    input: RequestInput;
};

export type MutationReservationCancelArgs = {
    input: ReservationCancelInput;
};

export type MutationReservationCreateArgs = {
    input: ReservationCreateInput;
};

export type MutationReservationSendMessageArgs = {
    input: ReservationSendMessageInput;
};

export type MutationReservationSubmitQuestionnaireArgs = {
    input: ReservationSubmitQuestionnaireInput;
};

export type MutationSetDefaultCardArgs = {
    input: SetDefaultCardInput;
};

export type MutationUserConfirmRegistrationWithTokenArgs = {
    confirmationToken: Scalars['String'];
};

export type MutationUserDeleteAccountArgs = {
    input: UserDeleteAccountInput;
};

export type MutationUserLoginArgs = {
    email: Scalars['String'];
    password: Scalars['String'];
};

export type MutationUserResendConfirmationWithTokenArgs = {
    confirmUrl: Scalars['String'];
    email: Scalars['String'];
};

export type MutationUserSendPasswordResetWithTokenArgs = {
    email: Scalars['String'];
    redirectUrl: Scalars['String'];
};

export type MutationUserSignUpArgs = {
    input: UserSignUpInput;
};

export type MutationUserUnlockAccountArgs = {
    input: UnlockAccountInput;
};

export type MutationUserUpdateEmailArgs = {
    input: UserUpdateEmailInput;
};

export type MutationUserUpdatePasswordArgs = {
    input: UserUpdatePasswordInput;
};

export type MutationUserUpdatePasswordWithTokenArgs = {
    input: UpdatePasswordWithTokenInput;
};

export type MutationUserUpdateProfileArgs = {
    input: UserUpdateProfileInput;
};

/** Input object for a reservation guest who the booking was made on behalf of by the registered user */
export type OnBehalfOfGuestInput = {
    email: Scalars['String'];
    firstName: Scalars['String'];
    firstNameKana?: InputMaybe<Scalars['String']>;
    lastName: Scalars['String'];
    lastNameKana?: InputMaybe<Scalars['String']>;
    phoneNumber: PhoneNumberInput;
};

export type PaginationInput = {
    limit?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
};

/** Contains a payment method (credit card/cash). */
export type PaymentMethod = CashPayment | CreditCard;

/** Autogenerated input type of PaymentMethodRemoveCreditCard */
export type PaymentMethodRemoveCreditCardInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: InputMaybe<Scalars['String']>;
    id: Scalars['ID'];
};

/** Autogenerated return type of PaymentMethodRemoveCreditCard. */
export type PaymentMethodRemoveCreditCardPayload = {
    __typename?: 'PaymentMethodRemoveCreditCardPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
};

/** Contains a phone number */
export type PhoneNumber = {
    __typename?: 'PhoneNumber';
    countryCode?: Maybe<Scalars['Int']>;
    e164Formatted: Scalars['String'];
    significant?: Maybe<Scalars['String']>;
};

/** Input object for a phone number */
export type PhoneNumberInput = {
    countryCode: Scalars['Int'];
    significant: Scalars['String'];
};

/** Contains information about a prefecture */
export type Prefecture = {
    __typename?: 'Prefecture';
    cities: Array<City>;
    id: Scalars['ID'];
    name: Scalars['String'];
};

/** Contains information about the price range for a service */
export type PriceRange = {
    __typename?: 'PriceRange';
    max: Scalars['Int'];
    min: Scalars['Int'];
    serviceType: ServiceType;
};

export type Query = {
    __typename?: 'Query';
    /** Available areas */
    areas: Array<Area>;
    /** Public Availabilities Search per venue */
    availabilitySearch?: Maybe<Array<AvailabilityUnion>>;
    /** List of restaurant courses for a scoped venue (id or hash id) */
    coursesSearch?: Maybe<Array<Course>>;
    /** List of Credit Cards for the current user order by creation desc */
    creditCardSearch: Array<CreditCard>;
    /** Available Cuisines */
    cuisines: Array<Cuisine>;
    /** Find a Reservation by ID */
    reservation?: Maybe<Reservation>;
    retrieveMarketingContent?: Maybe<Array<MarketingContent>>;
    retrieveMarketingEditorsPicks?: Maybe<Array<MarketingContent>>;
    retrieveMarketingFeaturedVenues?: Maybe<Array<MarketingContent>>;
    /** List of Secret Availbilities */
    secretAvailabilitySearch?: Maybe<Array<ReservableAvailability>>;
    /** The current user */
    user?: Maybe<User>;
    /** Find a venue by ID */
    venue: Venue;
    /** Find Venues */
    venuesSearch?: Maybe<VenueCollection>;
};

export type QueryAvailabilitySearchArgs = {
    date: Scalars['ISO8601Date'];
    partySize?: InputMaybe<Scalars['Int']>;
    seatingTypes?: InputMaybe<Array<SeatingType>>;
    serviceType?: InputMaybe<ServiceType>;
    venueId: Scalars['ID'];
};

export type QueryCoursesSearchArgs = {
    courseId?: InputMaybe<Scalars['ID']>;
    venueId: Scalars['ID'];
};

export type QueryCreditCardSearchArgs = {
    includeExpired?: InputMaybe<Scalars['Boolean']>;
};

export type QueryReservationArgs = {
    id: Scalars['ID'];
};

export type QuerySecretAvailabilitySearchArgs = {
    venueId: Scalars['ID'];
};

export type QueryVenueArgs = {
    id: Scalars['ID'];
};

export type QueryVenuesSearchArgs = {
    areaIds?: InputMaybe<Array<Scalars['ID']>>;
    cuisines?: InputMaybe<Array<Scalars['ID']>>;
    date?: InputMaybe<Scalars['ISO8601Date']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
    keyword?: InputMaybe<Scalars['String']>;
    maxPricePerPerson?: InputMaybe<Scalars['Int']>;
    minPricePerPerson?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<VenueOrderingEnum>;
    paginationInput?: InputMaybe<PaginationInput>;
    partySize?: InputMaybe<Scalars['Int']>;
    realTimeBooking?: InputMaybe<Scalars['Boolean']>;
    seatingTypes?: InputMaybe<Array<SeatingType>>;
    serviceTypes?: InputMaybe<Array<ServiceType>>;
};

/** Contains information about a questionnaire for a reservation */
export type Questionnaire = {
    __typename?: 'Questionnaire';
    feedback: Scalars['String'];
    satisfactionRating: QuestionnaireSatisfactionRating;
};

/** Satisfaction level given during post-reservation questionnaire */
export enum QuestionnaireSatisfactionRating {
    /** Dissatisfied */
    Dissatisfied = 'DISSATISFIED',
    /** Satisfied */
    Satisfied = 'SATISFIED',
    /** Very Dissatisfied */
    VeryDissatisfied = 'VERY_DISSATISFIED',
    /** Very satisfied */
    VerySatisfied = 'VERY_SATISFIED',
}

/** Contains information about a receipt for a reservation */
export type Receipt = {
    __typename?: 'Receipt';
    consumptionTax?: Maybe<Scalars['Int']>;
    receiptItems?: Maybe<Array<ReceiptItem>>;
    total?: Maybe<Scalars['Int']>;
};

/** Contains information about an item on a receipt */
export type ReceiptItem = {
    __typename?: 'ReceiptItem';
    description?: Maybe<Scalars['String']>;
    quantity?: Maybe<Scalars['Int']>;
    total?: Maybe<Scalars['Int']>;
    unitPrice?: Maybe<Scalars['Int']>;
};

/** Contains a recommendation */
export type Recommendation = {
    __typename?: 'Recommendation';
    ageRange?: Maybe<Scalars['String']>;
    comment: Scalars['String'];
    visitFrequency?: Maybe<VisitFrequency>;
    visitPurpose?: Maybe<VisitPurpose>;
    visitedAt: Scalars['String'];
};

/** Autogenerated input type of Request */
export type RequestInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: InputMaybe<Scalars['String']>;
    /** StripePaymentMethod id */
    id: Scalars['ID'];
};

/** An individual availability which is reservable with ties to a particular course option */
export type ReservableAvailability = {
    __typename?: 'ReservableAvailability';
    course: Course;
    endTime: Scalars['ISO8601DateTime'];
    id: Scalars['ID'];
    maxPartySize: Scalars['Int'];
    minPartySize: Scalars['Int'];
    seatingType: SeatingType;
    /** This is only filled for secret seats */
    secretSeatExpiration?: Maybe<Scalars['ISO8601DateTime']>;
    startTime: Scalars['ISO8601DateTime'];
};

/** Contains a reservation */
export type Reservation = {
    __typename?: 'Reservation';
    allergy: Scalars['String'];
    /** Hash of the cancellation policy information if cancellable; null for waitings */
    cancellationInfo?: Maybe<CancellationInfo>;
    /** Whether cancellation is possible in the current state of the reservation; waitings are always cancellable */
    cancellationPossible: Scalars['Boolean'];
    course: Course;
    dateTime?: Maybe<Scalars['ISO8601DateTime']>;
    hotelGuestName?: Maybe<Scalars['String']>;
    hotelName?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    mandator?: Maybe<BookedOnBehalfOfGuest>;
    memo: Scalars['String'];
    /** Number of people, or number of meals for takeout reservations */
    numberOfPeople: Scalars['Int'];
    paymentMethod?: Maybe<PaymentMethod>;
    questionnaire?: Maybe<Questionnaire>;
    receipt?: Maybe<Receipt>;
    /** Formal receipt (with customers name written on). */
    ryoshusho?: Maybe<RyoshushoType>;
    seatingType?: Maybe<SeatingType>;
    specialRequestOption?: Maybe<SpecialRequestOption>;
    specialRequestOptionMessage?: Maybe<Scalars['String']>;
    status: ReservationStatus;
    venue: Venue;
    visitFrequency?: Maybe<VisitFrequency>;
    visitPurpose?: Maybe<VisitPurpose>;
    /** The waiting list request for this reservation, if the reservation is not yet confirmed */
    waitingListRequest?: Maybe<WaitingListRequest>;
};

/** Autogenerated input type of ReservationCancel */
export type ReservationCancelInput = {
    /** Reason for requesting to cancel. Required for reservations. Not required for waiting list requests. */
    cancelReason?: InputMaybe<Scalars['String']>;
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: InputMaybe<Scalars['String']>;
    id: Scalars['ID'];
};

/** Autogenerated return type of ReservationCancel. */
export type ReservationCancelPayload = {
    __typename?: 'ReservationCancelPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of ReservationCreate */
export type ReservationCreateInput = {
    allergy?: InputMaybe<Scalars['String']>;
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: InputMaybe<Scalars['String']>;
    courseId: Scalars['ID'];
    /**
     * ID of the credit card used for online payment for the reservation. If no ID is
     * passed, reservation will be treated as cash payment (pay-at-venue).
     */
    creditCardId: Scalars['ID'];
    /** Date time for the reservation. Cannot be set with waitingListTimeRange. */
    dateTime?: InputMaybe<Scalars['ISO8601DateTime']>;
    hotelGuestName?: InputMaybe<Scalars['String']>;
    hotelName?: InputMaybe<Scalars['String']>;
    memo?: InputMaybe<Scalars['String']>;
    numberOfPeople: Scalars['Int'];
    /**
     * Guest information if the reservation is on behalf of someone other than the
     * registered user. Cannot be set with userGuest or if the reservation is a
     * waiting list request.
     */
    onBehalfOfGuest?: InputMaybe<OnBehalfOfGuestInput>;
    ryoshushoName?: InputMaybe<Scalars['String']>;
    /** Seating type for the reservation. Must be set for reservations. Cannot be set for waiting list requests */
    seatingType?: InputMaybe<SeatingType>;
    secretSeatId?: InputMaybe<Scalars['ID']>;
    /** Set are not required for backwards compatability, it should be treated as required */
    serviceType?: InputMaybe<ServiceType>;
    specialRequestOptionId?: InputMaybe<Scalars['ID']>;
    specialRequestOptionMessage?: InputMaybe<Scalars['String']>;
    /** Guest information if the reservation is for the registered user. Cannot be set with onBehalfOfGuest. */
    userGuest?: InputMaybe<UserGuestInput>;
    venueId: Scalars['ID'];
    visitFrequency: VisitFrequency;
    visitPurpose: VisitPurpose;
    /** Waiting list time range for a waiting list request. Cannot be set with dateTime */
    waitingListTimeRange?: InputMaybe<WaitingListTimeRangeInput>;
};

/** Autogenerated return type of ReservationCreate. */
export type ReservationCreatePayload = {
    __typename?: 'ReservationCreatePayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    /** Reservation created */
    reservation?: Maybe<Reservation>;
};

/** Autogenerated input type of ReservationSendMessage */
export type ReservationSendMessageInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: InputMaybe<Scalars['String']>;
    id: Scalars['ID'];
    message: Scalars['String'];
};

/** Autogenerated return type of ReservationSendMessage. */
export type ReservationSendMessagePayload = {
    __typename?: 'ReservationSendMessagePayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
};

/** Reservation statuses for reservations */
export enum ReservationStatus {
    /** Cancellation complete */
    CancellationConfirmed = 'CANCELLATION_CONFIRMED',
    /** Cancellation is being processed */
    CancellationRequested = 'CANCELLATION_REQUESTED',
    /** Reservation complete */
    Confirmed = 'CONFIRMED',
    /** Reservation not complete */
    Declined = 'DECLINED',
    /** No-show */
    NoShow = 'NO_SHOW',
    /** Confirming reservation */
    Pending = 'PENDING',
    /** Same-day cancellation */
    SameDayCancellation = 'SAME_DAY_CANCELLATION',
    /** Payment complete */
    Visited = 'VISITED',
    /** Waitlist period expired */
    WaitlistPeriodExpired = 'WAITLIST_PERIOD_EXPIRED',
}

/** Autogenerated input type of ReservationSubmitQuestionnaire */
export type ReservationSubmitQuestionnaireInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: InputMaybe<Scalars['String']>;
    feedback: Scalars['String'];
    id: Scalars['ID'];
    satisfactionRating: QuestionnaireSatisfactionRating;
};

/** Autogenerated return type of ReservationSubmitQuestionnaire. */
export type ReservationSubmitQuestionnairePayload = {
    __typename?: 'ReservationSubmitQuestionnairePayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
};

/** Contains ryoshusho (a formal receipt) information */
export type RyoshushoType = {
    __typename?: 'RyoshushoType';
    /** name of recipient */
    name: Scalars['String'];
    /** url to download pdf from */
    url?: Maybe<Scalars['String']>;
};

/** A category for a seating type */
export enum SeatingType {
    /** counter */
    Counter = 'COUNTER',
    /** none */
    None = 'NONE',
    /** private counter */
    PrivateCounter = 'PRIVATE_COUNTER',
    /** private room */
    PrivateRoom = 'PRIVATE_ROOM',
    /** table */
    Table = 'TABLE',
    /** takeout */
    Takeout = 'TAKEOUT',
}

/** Service type for a course, such as a time i.e. lunch, dinner */
export enum ServiceType {
    /** DINNER */
    Dinner = 'DINNER',
    /** LUNCH */
    Lunch = 'LUNCH',
}

/** Autogenerated input type of SetDefaultCard */
export type SetDefaultCardInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: InputMaybe<Scalars['String']>;
    id: Scalars['ID'];
};

/** A Stripe setup intent status */
export enum SetupIntentStatus {
    Canceled = 'canceled',
    Processing = 'processing',
    RequiresAction = 'requires_action',
    RequiresConfirmation = 'requires_confirmation',
    RequiresPaymentMethod = 'requires_payment_method',
    Succeeded = 'succeeded',
}

/** Contains a special request option that can be made when booking at a restaurant */
export type SpecialRequestOption = {
    __typename?: 'SpecialRequestOption';
    id: Scalars['ID'];
    messageRequired: Scalars['Boolean'];
    title: Scalars['String'];
};

/** Contains information about a town */
export type Town = {
    __typename?: 'Town';
    city: Array<City>;
    id: Scalars['ID'];
    name: Scalars['String'];
    venues: Array<Venue>;
};

/** Autogenerated input type of UnlockAccount */
export type UnlockAccountInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: InputMaybe<Scalars['String']>;
    token: Scalars['String'];
};

/** Autogenerated return type of UnlockAccount. */
export type UnlockAccountPayload = {
    __typename?: 'UnlockAccountPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of UpdatePasswordWithToken */
export type UpdatePasswordWithTokenInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: InputMaybe<Scalars['String']>;
    password: Scalars['String'];
    passwordConfirmation: Scalars['String'];
    token: Scalars['String'];
};

/** Autogenerated return type of UpdatePasswordWithToken. */
export type UpdatePasswordWithTokenPayload = {
    __typename?: 'UpdatePasswordWithTokenPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
};

/** Contains information about a user */
export type User = {
    __typename?: 'User';
    birthday?: Maybe<Scalars['ISO8601Date']>;
    bookmarkedVenues: Array<Venue>;
    companyName?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    featuredMailNotification: FeaturedMailNotificationEnum;
    firstName?: Maybe<Scalars['String']>;
    firstNameKana?: Maybe<Scalars['String']>;
    gender?: Maybe<Gender>;
    id: Scalars['ID'];
    lastName?: Maybe<Scalars['String']>;
    lastNameKana?: Maybe<Scalars['String']>;
    newsletter: Scalars['Boolean'];
    phoneNumber?: Maybe<PhoneNumber>;
    reservations: Array<Reservation>;
};

/** Autogenerated return type of UserConfirmRegistrationWithToken. */
export type UserConfirmRegistrationWithTokenPayload = {
    __typename?: 'UserConfirmRegistrationWithTokenPayload';
    authenticatable: User;
    /** Authentication credentials. Null unless user is signed in after confirmation. */
    credentials?: Maybe<Credential>;
};

/** Autogenerated input type of UserDeleteAccount */
export type UserDeleteAccountInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: InputMaybe<Scalars['String']>;
    /** Note by user (i.e. Deleting my account due to...) */
    note: Scalars['String'];
    reasons: Array<AccountDeletionReason>;
};

/** Autogenerated return type of UserDeleteAccount. */
export type UserDeleteAccountPayload = {
    __typename?: 'UserDeleteAccountPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
};

/** Input object for a reservation guest who is also the registered user */
export type UserGuestInput = {
    firstNameKana?: InputMaybe<Scalars['String']>;
    lastNameKana?: InputMaybe<Scalars['String']>;
    phoneNumber: PhoneNumberInput;
};

/** Autogenerated return type of UserLogin. */
export type UserLoginPayload = {
    __typename?: 'UserLoginPayload';
    authenticatable: User;
    credentials: Credential;
};

/** Autogenerated return type of UserLogout. */
export type UserLogoutPayload = {
    __typename?: 'UserLogoutPayload';
    authenticatable: User;
};

/** Autogenerated return type of UserResendConfirmationWithToken. */
export type UserResendConfirmationWithTokenPayload = {
    __typename?: 'UserResendConfirmationWithTokenPayload';
    message: Scalars['String'];
};

/** Autogenerated return type of UserSendPasswordResetWithToken. */
export type UserSendPasswordResetWithTokenPayload = {
    __typename?: 'UserSendPasswordResetWithTokenPayload';
    message: Scalars['String'];
};

/** Autogenerated input type of UserSignUp */
export type UserSignUpInput = {
    birthday: Scalars['ISO8601Date'];
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: InputMaybe<Scalars['String']>;
    email: Scalars['String'];
    /** defaults to DENY as this is opt-in consent */
    featuredMailNotification?: InputMaybe<FeaturedMailNotificationEnum>;
    firstName: Scalars['String'];
    firstNameKana?: InputMaybe<Scalars['String']>;
    gender?: InputMaybe<Gender>;
    lastName: Scalars['String'];
    lastNameKana?: InputMaybe<Scalars['String']>;
    newsletter: Scalars['Boolean'];
    password: Scalars['String'];
};

/** Autogenerated return type of UserSignUp. */
export type UserSignUpPayload = {
    __typename?: 'UserSignUpPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of UserUpdateEmail */
export type UserUpdateEmailInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: InputMaybe<Scalars['String']>;
    email: Scalars['String'];
};

/** Autogenerated return type of UserUpdateEmail. */
export type UserUpdateEmailPayload = {
    __typename?: 'UserUpdateEmailPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    /** Whether a confirmation email was sent */
    confirmationEmailSent?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated input type of UserUpdatePassword */
export type UserUpdatePasswordInput = {
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: InputMaybe<Scalars['String']>;
    currentPassword: Scalars['String'];
    newPassword: Scalars['String'];
};

/** Autogenerated return type of UserUpdatePassword. */
export type UserUpdatePasswordPayload = {
    __typename?: 'UserUpdatePasswordPayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of UserUpdateProfile */
export type UserUpdateProfileInput = {
    birthday?: InputMaybe<Scalars['ISO8601Date']>;
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: InputMaybe<Scalars['String']>;
    companyName?: InputMaybe<Scalars['String']>;
    featuredMailNotification?: InputMaybe<FeaturedMailNotificationEnum>;
    firstName?: InputMaybe<Scalars['String']>;
    firstNameKana?: InputMaybe<Scalars['String']>;
    gender?: InputMaybe<Gender>;
    lastName?: InputMaybe<Scalars['String']>;
    lastNameKana?: InputMaybe<Scalars['String']>;
    newsletter?: InputMaybe<Scalars['Boolean']>;
    phoneNumber?: InputMaybe<PhoneNumberInput>;
};

/** Autogenerated return type of UserUpdateProfile. */
export type UserUpdateProfilePayload = {
    __typename?: 'UserUpdateProfilePayload';
    /** A unique identifier for the client performing the mutation. */
    clientMutationId?: Maybe<Scalars['String']>;
    /** User after mutation */
    user?: Maybe<User>;
};

/** Contains information about a venue */
export type Venue = {
    __typename?: 'Venue';
    address: Address;
    addressHidden: Scalars['Boolean'];
    area?: Maybe<Area>;
    availabilityCalendar: AvailabilityCalendar;
    blurb: Scalars['String'];
    businessHours: Scalars['String'];
    courses: Array<Course>;
    cuisines: Array<Cuisine>;
    frequentlyAskedQuestions: Array<FrequentlyAskedQuestion>;
    googleMapUrl?: Maybe<Scalars['String']>;
    holidays: Scalars['String'];
    id: Scalars['ID'];
    imageUrls: Array<Scalars['String']>;
    isPublished: Scalars['Boolean'];
    latitude?: Maybe<Scalars['Float']>;
    limitedScopeUrlHash: Scalars['String'];
    localizedAddress: Scalars['String'];
    longDescription: Scalars['String'];
    longitude?: Maybe<Scalars['Float']>;
    name: Scalars['String'];
    nearestStations?: Maybe<Array<Scalars['String']>>;
    phoneNumber?: Maybe<Scalars['String']>;
    priceRanges: Array<PriceRange>;
    realTimeBooking: Scalars['Boolean'];
    recommendations: Array<Recommendation>;
    reservationTerms?: Maybe<Scalars['String']>;
    services: Array<Scalars['String']>;
    specialRequestOptions: Array<SpecialRequestOption>;
    thumbnail?: Maybe<Scalars['String']>;
    transactionsAllowed: Scalars['Boolean'];
    websiteUrl?: Maybe<Scalars['String']>;
};

export type VenueCollection = {
    __typename?: 'VenueCollection';
    collection: Array<Venue>;
    metadata: CollectionMetadata;
};

export enum VenueOrderingEnum {
    CreationDesc = 'CREATION_DESC',
    PositionAsc = 'POSITION_ASC',
}

/** How often a customer has visited a venue */
export enum VisitFrequency {
    /** First visit */
    FirstVisit = 'FIRST_VISIT',
    /** Second visit */
    SecondVisit = 'SECOND_VISIT',
    /** Third or more visit */
    ThirdOrMoreVisit = 'THIRD_OR_MORE_VISIT',
}

/** The reason a customer is visiting a venue */
export enum VisitPurpose {
    /** Anniversary */
    Anniversary = 'ANNIVERSARY',
    /** Meal with a client */
    ClientDinner = 'CLIENT_DINNER',
    /** Date */
    Date = 'DATE',
    /** External system reservation */
    ExternalSystemReservation = 'EXTERNAL_SYSTEM_RESERVATION',
    /** Meal with family */
    Family = 'FAMILY',
    /** Meal with colleague(s) */
    JobFriendDinner = 'JOB_FRIEND_DINNER',
    /** Meal with friends */
    MealWithFriend = 'MEAL_WITH_FRIEND',
    /** Other */
    Other = 'OTHER',
}

/** Contains a waiting list request for a reservation */
export type WaitingListRequest = {
    __typename?: 'WaitingListRequest';
    dateTimeRangeEnd?: Maybe<Scalars['ISO8601DateTime']>;
    dateTimeRangeStart?: Maybe<Scalars['ISO8601DateTime']>;
    deadline?: Maybe<Scalars['ISO8601Date']>;
    id: Scalars['ID'];
    reservation: Reservation;
};

/** Input object for the waiting list time range of a reservation */
export type WaitingListTimeRangeInput = {
    dateTimeRangeEnd: Scalars['ISO8601DateTime'];
    dateTimeRangeStart: Scalars['ISO8601DateTime'];
    deadline: Scalars['ISO8601Date'];
};

/** an abstract representation of an availability for a particular course lacking availability */
export type WaitlistableAvailability = {
    __typename?: 'WaitlistableAvailability';
    course: Course;
    endTime: Scalars['ISO8601DateTime'];
    maxPartySize: Scalars['Int'];
    minPartySize: Scalars['Int'];
    startTime: Scalars['ISO8601DateTime'];
};

export type AddBookmarkMutationVariables = Exact<{
    venueId: Scalars['ID'];
}>;

export type AddBookmarkMutation = {
    __typename?: 'Mutation';
    bookmarkAddVenue?: {
        __typename?: 'BookmarkAddVenuePayload';
        clientMutationId?: string | null;
    } | null;
};

export type RemoveBookmarkMutationVariables = Exact<{
    venueId: Scalars['ID'];
}>;

export type RemoveBookmarkMutation = {
    __typename?: 'Mutation';
    bookmarkRemoveVenue?: {
        __typename?: 'BookmarkRemoveVenuePayload';
        clientMutationId?: string | null;
    } | null;
};

export type LogoutMutationVariables = Exact<{[key: string]: never}>;

export type LogoutMutation = {
    __typename?: 'Mutation';
    userLogout?: {
        __typename?: 'UserLogoutPayload';
        authenticatable: {__typename?: 'User'; id: string};
    } | null;
};

export type AvailabilitySearchQueryVariables = Exact<{
    venueId: Scalars['ID'];
    date: Scalars['ISO8601Date'];
}>;

export type AvailabilitySearchQuery = {
    __typename?: 'Query';
    availabilitySearch?: Array<
        | {
              __typename?: 'ReservableAvailability';
              id: string;
              startTime: any;
              endTime: any;
              seatingType: SeatingType;
              maxPartySize: number;
              minPartySize: number;
              course: {
                  __typename?: 'Course';
                  fixedPrice?: number | null;
                  fixedTitle?: string | null;
                  costPerGuest: number;
                  id: string;
                  name: string;
                  serviceType: ServiceType;
                  summary?: string | null;
                  supplementaryInformation?: string | null;
              };
          }
        | {
              __typename?: 'WaitlistableAvailability';
              startTime: any;
              endTime: any;
              maxPartySize: number;
              minPartySize: number;
              course: {
                  __typename?: 'Course';
                  fixedPrice?: number | null;
                  fixedTitle?: string | null;
                  costPerGuest: number;
                  id: string;
                  name: string;
                  serviceType: ServiceType;
                  summary?: string | null;
                  supplementaryInformation?: string | null;
              };
          }
    > | null;
};

export type AvailabilityCalendarFragment = {
    __typename?: 'Venue';
    availabilityCalendar: {
        __typename?: 'AvailabilityCalendar';
        reservationDates: Array<any>;
        waitlistDates: Array<any>;
    };
};

export type VenueAvailabilityCalendarQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type VenueAvailabilityCalendarQuery = {
    __typename?: 'Query';
    venue: {
        __typename?: 'Venue';
        availabilityCalendar: {
            __typename?: 'AvailabilityCalendar';
            reservationDates: Array<any>;
            waitlistDates: Array<any>;
        };
    };
};

export type SecretAvailabilitySearchQueryVariables = Exact<{
    venueId: Scalars['ID'];
}>;

export type SecretAvailabilitySearchQuery = {
    __typename?: 'Query';
    secretAvailabilitySearch?: Array<{
        __typename?: 'ReservableAvailability';
        startTime: any;
        endTime: any;
        id: string;
        seatingType: SeatingType;
        maxPartySize: number;
        minPartySize: number;
        secretSeatExpiration?: any | null;
        course: {
            __typename?: 'Course';
            fixedPrice?: number | null;
            fixedTitle?: string | null;
            costPerGuest: number;
            id: string;
            name: string;
            serviceType: ServiceType;
            summary?: string | null;
            supplementaryInformation?: string | null;
        };
    }> | null;
};

export type CreateReservationMutationVariables = Exact<{
    input: ReservationCreateInput;
}>;

export type CreateReservationMutation = {
    __typename?: 'Mutation';
    reservationCreate?: {
        __typename?: 'ReservationCreatePayload';
        clientMutationId?: string | null;
        reservation?: {__typename?: 'Reservation'; id: string} | null;
    } | null;
};

export type ReserveUserProfileFragment = {
    __typename?: 'User';
    email?: string | null;
    firstName?: string | null;
    firstNameKana?: string | null;
    id: string;
    lastName?: string | null;
    lastNameKana?: string | null;
    phoneNumber?: {
        __typename?: 'PhoneNumber';
        countryCode?: number | null;
        significant?: string | null;
    } | null;
};

export type ReserveVenueFragment = {
    __typename?: 'Venue';
    id: string;
    name: string;
    realTimeBooking: boolean;
    reservationTerms?: string | null;
    specialRequestOptions: Array<{
        __typename?: 'SpecialRequestOption';
        id: string;
        messageRequired: boolean;
        title: string;
    }>;
};

export type ReserveQueryVariables = Exact<{
    venueId: Scalars['ID'];
    courseId: Scalars['ID'];
}>;

export type ReserveQuery = {
    __typename?: 'Query';
    coursesSearch?: Array<{
        __typename?: 'Course';
        fixedPrice?: number | null;
        fixedTitle?: string | null;
        costPerGuest: number;
        id: string;
        name: string;
        serviceType: ServiceType;
        summary?: string | null;
        supplementaryInformation?: string | null;
    }> | null;
    user?: {
        __typename?: 'User';
        email?: string | null;
        firstName?: string | null;
        firstNameKana?: string | null;
        id: string;
        lastName?: string | null;
        lastNameKana?: string | null;
        phoneNumber?: {
            __typename?: 'PhoneNumber';
            countryCode?: number | null;
            significant?: string | null;
        } | null;
    } | null;
    venue: {
        __typename?: 'Venue';
        id: string;
        name: string;
        realTimeBooking: boolean;
        reservationTerms?: string | null;
        specialRequestOptions: Array<{
            __typename?: 'SpecialRequestOption';
            id: string;
            messageRequired: boolean;
            title: string;
        }>;
    };
};

export type AddCardMutationVariables = Exact<{
    stripeToken: Scalars['String'];
    isDefaultCard?: InputMaybe<Scalars['Boolean']>;
}>;

export type AddCardMutation = {
    __typename?: 'Mutation';
    addCard: {
        __typename?: 'CreditCardWithClientSecret';
        clientSecret?: string | null;
        creditCard: {
            __typename?: 'CreditCard';
            id: string;
            stripeSetupIntentStatus?: SetupIntentStatus | null;
            confirmationNeeded: boolean;
        };
    };
};

export type SetDefaultCardMutationVariables = Exact<{
    id: Scalars['ID'];
}>;

export type SetDefaultCardMutation = {
    __typename?: 'Mutation';
    setDefaultCard: {__typename?: 'CreditCard'; id: string};
};

export type RequestSetupIntentMutationVariables = Exact<{
    id: Scalars['ID'];
}>;

export type RequestSetupIntentMutation = {
    __typename?: 'Mutation';
    requestSetupIntent: {
        __typename?: 'CreditCardWithClientSecret';
        clientSecret?: string | null;
        creditCard: {
            __typename?: 'CreditCard';
            stripeSetupIntentStatus?: SetupIntentStatus | null;
            confirmationNeeded: boolean;
        };
    };
};

export type ConfirmSetupIntentMutationVariables = Exact<{
    id: Scalars['ID'];
}>;

export type ConfirmSetupIntentMutation = {
    __typename?: 'Mutation';
    confirmSetupIntent: {
        __typename?: 'CreditCard';
        stripeSetupIntentStatus?: SetupIntentStatus | null;
    };
};

export type PaymentMethodRemoveMutationVariables = Exact<{
    id: Scalars['ID'];
}>;

export type PaymentMethodRemoveMutation = {
    __typename?: 'Mutation';
    paymentMethodRemoveCreditCard?: {
        __typename?: 'PaymentMethodRemoveCreditCardPayload';
        clientMutationId?: string | null;
    } | null;
};

export type CreditCardSearchQueryVariables = Exact<{
    includeExpired?: InputMaybe<Scalars['Boolean']>;
}>;

export type CreditCardSearchQuery = {
    __typename?: 'Query';
    creditCardSearch: Array<{
        __typename?: 'CreditCard';
        id: string;
        cardBrand: CreditCardBrand;
        cardExpiryMonth: number;
        cardExpiryYear: number;
        cardLast4: string;
        cardInUse: boolean;
        confirmationNeeded: boolean;
        defaultCard: boolean;
        stripeToken: string;
    }>;
};

export type UserUpdateProfileMutationVariables = Exact<{
    birthday?: InputMaybe<Scalars['ISO8601Date']>;
    companyName?: InputMaybe<Scalars['String']>;
    firstName?: InputMaybe<Scalars['String']>;
    firstNameKana?: InputMaybe<Scalars['String']>;
    gender?: InputMaybe<Gender>;
    lastName?: InputMaybe<Scalars['String']>;
    lastNameKana?: InputMaybe<Scalars['String']>;
    newsletter: Scalars['Boolean'];
    phoneNumber?: InputMaybe<PhoneNumberInput>;
}>;

export type UserUpdateProfileMutation = {
    __typename?: 'Mutation';
    userUpdateProfile?: {
        __typename?: 'UserUpdateProfilePayload';
        clientMutationId?: string | null;
        user?: {
            __typename?: 'User';
            birthday?: any | null;
            companyName?: string | null;
            firstName?: string | null;
            firstNameKana?: string | null;
            gender?: Gender | null;
            id: string;
            lastName?: string | null;
            lastNameKana?: string | null;
            newsletter: boolean;
            featuredMailNotification: FeaturedMailNotificationEnum;
            phoneNumber?: {
                __typename?: 'PhoneNumber';
                countryCode?: number | null;
                significant?: string | null;
            } | null;
        } | null;
    } | null;
};

export type UpdateEmailPreferencesMutationVariables = Exact<{
    newsletter: Scalars['Boolean'];
    featuredMailNotification?: InputMaybe<FeaturedMailNotificationEnum>;
}>;

export type UpdateEmailPreferencesMutation = {
    __typename?: 'Mutation';
    userUpdateProfile?: {
        __typename?: 'UserUpdateProfilePayload';
        clientMutationId?: string | null;
        user?: {
            __typename?: 'User';
            birthday?: any | null;
            companyName?: string | null;
            firstName?: string | null;
            firstNameKana?: string | null;
            gender?: Gender | null;
            id: string;
            lastName?: string | null;
            lastNameKana?: string | null;
            newsletter: boolean;
            featuredMailNotification: FeaturedMailNotificationEnum;
            phoneNumber?: {
                __typename?: 'PhoneNumber';
                countryCode?: number | null;
                significant?: string | null;
            } | null;
        } | null;
    } | null;
};

export type UserProfileFragment = {
    __typename?: 'User';
    birthday?: any | null;
    companyName?: string | null;
    firstName?: string | null;
    firstNameKana?: string | null;
    gender?: Gender | null;
    id: string;
    lastName?: string | null;
    lastNameKana?: string | null;
    newsletter: boolean;
    featuredMailNotification: FeaturedMailNotificationEnum;
    phoneNumber?: {
        __typename?: 'PhoneNumber';
        countryCode?: number | null;
        significant?: string | null;
    } | null;
};

export type UserProfileQueryVariables = Exact<{[key: string]: never}>;

export type UserProfileQuery = {
    __typename?: 'Query';
    user?: {
        __typename?: 'User';
        birthday?: any | null;
        companyName?: string | null;
        firstName?: string | null;
        firstNameKana?: string | null;
        gender?: Gender | null;
        id: string;
        lastName?: string | null;
        lastNameKana?: string | null;
        newsletter: boolean;
        featuredMailNotification: FeaturedMailNotificationEnum;
        phoneNumber?: {
            __typename?: 'PhoneNumber';
            countryCode?: number | null;
            significant?: string | null;
        } | null;
    } | null;
};

export type CancelReservationMutationVariables = Exact<{
    id: Scalars['ID'];
    cancelReason?: InputMaybe<Scalars['String']>;
}>;

export type CancelReservationMutation = {
    __typename?: 'Mutation';
    reservationCancel?: {
        __typename?: 'ReservationCancelPayload';
        clientMutationId?: string | null;
    } | null;
};

export type ReservationCancelQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type ReservationCancelQuery = {
    __typename?: 'Query';
    reservation?: {
        __typename?: 'Reservation';
        cancellationPossible: boolean;
        dateTime?: any | null;
        id: string;
        numberOfPeople: number;
        status: ReservationStatus;
        cancellationInfo?: {
            __typename?: 'CancellationInfo';
            amount: number;
            percentage: number;
        } | null;
        venue: {
            __typename?: 'Venue';
            name: string;
            id: string;
            imageUrls: Array<string>;
            thumbnail?: string | null;
            realTimeBooking: boolean;
            phoneNumber?: string | null;
            isPublished: boolean;
            limitedScopeUrlHash: string;
        };
        waitingListRequest?: {
            __typename?: 'WaitingListRequest';
            dateTimeRangeEnd?: any | null;
            dateTimeRangeStart?: any | null;
            id: string;
            deadline?: any | null;
        } | null;
    } | null;
};

export type SubmitQuestionnaireMutationVariables = Exact<{
    id: Scalars['ID'];
    satisfactionRating: QuestionnaireSatisfactionRating;
    feedback: Scalars['String'];
}>;

export type SubmitQuestionnaireMutation = {
    __typename?: 'Mutation';
    reservationSubmitQuestionnaire?: {
        __typename?: 'ReservationSubmitQuestionnairePayload';
        clientMutationId?: string | null;
    } | null;
};

export type SendMessageMutationVariables = Exact<{
    id: Scalars['ID'];
    message: Scalars['String'];
}>;

export type SendMessageMutation = {
    __typename?: 'Mutation';
    reservationSendMessage?: {
        __typename?: 'ReservationSendMessagePayload';
        clientMutationId?: string | null;
    } | null;
};

export type ReservationMessageQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type ReservationMessageQuery = {
    __typename?: 'Query';
    reservation?: {
        __typename?: 'Reservation';
        cancellationPossible: boolean;
        dateTime?: any | null;
        id: string;
        numberOfPeople: number;
        status: ReservationStatus;
        venue: {
            __typename?: 'Venue';
            name: string;
            id: string;
            imageUrls: Array<string>;
            thumbnail?: string | null;
            realTimeBooking: boolean;
            phoneNumber?: string | null;
            isPublished: boolean;
            limitedScopeUrlHash: string;
        };
        waitingListRequest?: {
            __typename?: 'WaitingListRequest';
            dateTimeRangeEnd?: any | null;
            dateTimeRangeStart?: any | null;
            id: string;
            deadline?: any | null;
        } | null;
    } | null;
};

export type ReservationVenueFragment = {
    __typename?: 'Reservation';
    venue: {
        __typename?: 'Venue';
        name: string;
        id: string;
        imageUrls: Array<string>;
        thumbnail?: string | null;
        realTimeBooking: boolean;
        phoneNumber?: string | null;
        isPublished: boolean;
        limitedScopeUrlHash: string;
    };
};

export type ReservationWaitingListRequestFragment = {
    __typename?: 'Reservation';
    waitingListRequest?: {
        __typename?: 'WaitingListRequest';
        dateTimeRangeEnd?: any | null;
        dateTimeRangeStart?: any | null;
        id: string;
        deadline?: any | null;
    } | null;
};

export type ReservationQuestionnaireFragment = {
    __typename?: 'Reservation';
    questionnaire?: {
        __typename?: 'Questionnaire';
        feedback: string;
        satisfactionRating: QuestionnaireSatisfactionRating;
    } | null;
};

export type ReservationRyoshushoFragment = {
    __typename?: 'Reservation';
    ryoshusho?: {__typename?: 'RyoshushoType'; url?: string | null} | null;
};

export type ReservationBaseFragment = {
    __typename?: 'Reservation';
    cancellationPossible: boolean;
    dateTime?: any | null;
    id: string;
    numberOfPeople: number;
    status: ReservationStatus;
};

export type ReservationDetailFragment = {
    __typename?: 'Reservation';
    allergy: string;
    hotelGuestName?: string | null;
    hotelName?: string | null;
    id: string;
    memo: string;
    seatingType?: SeatingType | null;
    specialRequestOptionMessage?: string | null;
    visitFrequency?: VisitFrequency | null;
    visitPurpose?: VisitPurpose | null;
    course: {__typename?: 'Course'; id: string; name: string};
    mandator?: {
        __typename?: 'BookedOnBehalfOfGuest';
        email: string;
        firstName: string;
        firstNameKana?: string | null;
        lastName: string;
        lastNameKana?: string | null;
        phoneNumber: {
            __typename?: 'PhoneNumber';
            countryCode?: number | null;
            significant?: string | null;
        };
    } | null;
    paymentMethod?:
        | {__typename?: 'CashPayment'}
        | {
              __typename?: 'CreditCard';
              cardBrand: CreditCardBrand;
              cardExpiryMonth: number;
              cardExpiryYear: number;
              cardLast4: string;
              id: string;
          }
        | null;
    receipt?: {
        __typename?: 'Receipt';
        consumptionTax?: number | null;
        total?: number | null;
        receiptItems?: Array<{
            __typename?: 'ReceiptItem';
            description?: string | null;
            quantity?: number | null;
            total?: number | null;
            unitPrice?: number | null;
        }> | null;
    } | null;
    ryoshusho?: {
        __typename?: 'RyoshushoType';
        name: string;
        url?: string | null;
    } | null;
    specialRequestOption?: {
        __typename?: 'SpecialRequestOption';
        id: string;
        title: string;
    } | null;
};

export type ReservationsQueryVariables = Exact<{[key: string]: never}>;

export type ReservationsQuery = {
    __typename?: 'Query';
    user?: {
        __typename?: 'User';
        email?: string | null;
        firstName?: string | null;
        firstNameKana?: string | null;
        id: string;
        lastName?: string | null;
        lastNameKana?: string | null;
        reservations: Array<{
            __typename?: 'Reservation';
            cancellationPossible: boolean;
            dateTime?: any | null;
            id: string;
            numberOfPeople: number;
            status: ReservationStatus;
            venue: {
                __typename?: 'Venue';
                name: string;
                id: string;
                imageUrls: Array<string>;
                thumbnail?: string | null;
                realTimeBooking: boolean;
                phoneNumber?: string | null;
                isPublished: boolean;
                limitedScopeUrlHash: string;
            };
            waitingListRequest?: {
                __typename?: 'WaitingListRequest';
                dateTimeRangeEnd?: any | null;
                dateTimeRangeStart?: any | null;
                id: string;
                deadline?: any | null;
            } | null;
            questionnaire?: {
                __typename?: 'Questionnaire';
                feedback: string;
                satisfactionRating: QuestionnaireSatisfactionRating;
            } | null;
            ryoshusho?: {
                __typename?: 'RyoshushoType';
                url?: string | null;
            } | null;
        }>;
        phoneNumber?: {
            __typename?: 'PhoneNumber';
            countryCode?: number | null;
            significant?: string | null;
        } | null;
    } | null;
};

export type ReservationDetailQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type ReservationDetailQuery = {
    __typename?: 'Query';
    reservation?: {
        __typename?: 'Reservation';
        allergy: string;
        hotelGuestName?: string | null;
        hotelName?: string | null;
        id: string;
        memo: string;
        seatingType?: SeatingType | null;
        specialRequestOptionMessage?: string | null;
        visitFrequency?: VisitFrequency | null;
        visitPurpose?: VisitPurpose | null;
        course: {__typename?: 'Course'; id: string; name: string};
        mandator?: {
            __typename?: 'BookedOnBehalfOfGuest';
            email: string;
            firstName: string;
            firstNameKana?: string | null;
            lastName: string;
            lastNameKana?: string | null;
            phoneNumber: {
                __typename?: 'PhoneNumber';
                countryCode?: number | null;
                significant?: string | null;
            };
        } | null;
        paymentMethod?:
            | {__typename?: 'CashPayment'}
            | {
                  __typename?: 'CreditCard';
                  cardBrand: CreditCardBrand;
                  cardExpiryMonth: number;
                  cardExpiryYear: number;
                  cardLast4: string;
                  id: string;
              }
            | null;
        receipt?: {
            __typename?: 'Receipt';
            consumptionTax?: number | null;
            total?: number | null;
            receiptItems?: Array<{
                __typename?: 'ReceiptItem';
                description?: string | null;
                quantity?: number | null;
                total?: number | null;
                unitPrice?: number | null;
            }> | null;
        } | null;
        ryoshusho?: {
            __typename?: 'RyoshushoType';
            name: string;
            url?: string | null;
        } | null;
        specialRequestOption?: {
            __typename?: 'SpecialRequestOption';
            id: string;
            title: string;
        } | null;
    } | null;
};

export type UpdateEmailMutationVariables = Exact<{
    email: Scalars['String'];
}>;

export type UpdateEmailMutation = {
    __typename?: 'Mutation';
    userUpdateEmail?: {
        __typename?: 'UserUpdateEmailPayload';
        confirmationEmailSent?: boolean | null;
    } | null;
};

export type UpdatePasswordMutationVariables = Exact<{
    currentPassword: Scalars['String'];
    newPassword: Scalars['String'];
}>;

export type UpdatePasswordMutation = {
    __typename?: 'Mutation';
    userUpdatePassword?: {
        __typename?: 'UserUpdatePasswordPayload';
        clientMutationId?: string | null;
    } | null;
};

export type DeleteAccountMutationVariables = Exact<{
    note: Scalars['String'];
    reasons: Array<AccountDeletionReason> | AccountDeletionReason;
}>;

export type DeleteAccountMutation = {
    __typename?: 'Mutation';
    userDeleteAccount?: {
        __typename?: 'UserDeleteAccountPayload';
        clientMutationId?: string | null;
    } | null;
};

export type LoginMutationVariables = Exact<{
    email: Scalars['String'];
    password: Scalars['String'];
}>;

export type LoginMutation = {
    __typename?: 'Mutation';
    userLogin?: {
        __typename?: 'UserLoginPayload';
        credentials: {
            __typename?: 'Credential';
            accessToken: string;
            uid: string;
            tokenType: string;
            expiry: number;
            client: string;
        };
    } | null;
};

export type SignupMutationVariables = Exact<{
    birthday: Scalars['ISO8601Date'];
    email: Scalars['String'];
    firstName: Scalars['String'];
    firstNameKana?: InputMaybe<Scalars['String']>;
    lastName: Scalars['String'];
    lastNameKana?: InputMaybe<Scalars['String']>;
    newsletter: Scalars['Boolean'];
    password: Scalars['String'];
}>;

export type SignupMutation = {
    __typename?: 'Mutation';
    userSignUp?: {
        __typename?: 'UserSignUpPayload';
        clientMutationId?: string | null;
    } | null;
};

export type ForgotPasswordMutationVariables = Exact<{
    email: Scalars['String'];
    redirectUrl: Scalars['String'];
}>;

export type ForgotPasswordMutation = {
    __typename?: 'Mutation';
    userSendPasswordResetWithToken?: {
        __typename?: 'UserSendPasswordResetWithTokenPayload';
        message: string;
    } | null;
};

export type ResendConfirmationMutationVariables = Exact<{
    email: Scalars['String'];
    confirmUrl: Scalars['String'];
}>;

export type ResendConfirmationMutation = {
    __typename?: 'Mutation';
    userResendConfirmationWithToken?: {
        __typename?: 'UserResendConfirmationWithTokenPayload';
        message: string;
    } | null;
};

export type ResetPasswordMutationVariables = Exact<{
    password: Scalars['String'];
    passwordConfirmation: Scalars['String'];
    token: Scalars['String'];
}>;

export type ResetPasswordMutation = {
    __typename?: 'Mutation';
    userUpdatePasswordWithToken?: {
        __typename?: 'UpdatePasswordWithTokenPayload';
        clientMutationId?: string | null;
    } | null;
};

export type SignupConfirmationMutationVariables = Exact<{
    token: Scalars['String'];
}>;

export type SignupConfirmationMutation = {
    __typename?: 'Mutation';
    userConfirmRegistrationWithToken?: {
        __typename?: 'UserConfirmRegistrationWithTokenPayload';
        authenticatable: {__typename?: 'User'; id: string};
        credentials?: {
            __typename?: 'Credential';
            accessToken: string;
            client: string;
            expiry: number;
            tokenType: string;
            uid: string;
        } | null;
    } | null;
};

export type UnlockAccountMutationVariables = Exact<{
    token: Scalars['String'];
}>;

export type UnlockAccountMutation = {
    __typename?: 'Mutation';
    userUnlockAccount?: {
        __typename?: 'UnlockAccountPayload';
        clientMutationId?: string | null;
    } | null;
};

export type SearchPropertiesQueryVariables = Exact<{[key: string]: never}>;

export type SearchPropertiesQuery = {
    __typename?: 'Query';
    areas: Array<{__typename?: 'Area'; id: string; name: string}>;
    cuisines: Array<{__typename?: 'Cuisine'; id: string; name: string}>;
};

export type HomePageQueryVariables = Exact<{
    featuredVenueIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type HomePageQuery = {
    __typename?: 'Query';
    newArrivals?: {
        __typename?: 'VenueCollection';
        collection: Array<{
            __typename?: 'Venue';
            blurb: string;
            id: string;
            imageUrls: Array<string>;
            isPublished: boolean;
            limitedScopeUrlHash: string;
            name: string;
            realTimeBooking: boolean;
            thumbnail?: string | null;
            address: {
                __typename?: 'Address';
                prefecture: {
                    __typename?: 'Prefecture';
                    name: string;
                    id: string;
                };
                town: {__typename?: 'Town'; name: string};
            };
            area?: {__typename?: 'Area'; name: string; id: string} | null;
            cuisines: Array<{__typename?: 'Cuisine'; id: string; name: string}>;
            priceRanges: Array<{
                __typename?: 'PriceRange';
                max: number;
                min: number;
                serviceType: ServiceType;
            }>;
        }>;
    } | null;
    featured?: {
        __typename?: 'VenueCollection';
        collection: Array<{
            __typename?: 'Venue';
            blurb: string;
            id: string;
            imageUrls: Array<string>;
            isPublished: boolean;
            limitedScopeUrlHash: string;
            name: string;
            realTimeBooking: boolean;
            thumbnail?: string | null;
            address: {
                __typename?: 'Address';
                prefecture: {
                    __typename?: 'Prefecture';
                    name: string;
                    id: string;
                };
                town: {__typename?: 'Town'; name: string};
            };
            area?: {__typename?: 'Area'; name: string; id: string} | null;
            cuisines: Array<{__typename?: 'Cuisine'; id: string; name: string}>;
            priceRanges: Array<{
                __typename?: 'PriceRange';
                max: number;
                min: number;
                serviceType: ServiceType;
            }>;
        }>;
    } | null;
    pickUp?: {
        __typename?: 'VenueCollection';
        collection: Array<{
            __typename?: 'Venue';
            blurb: string;
            id: string;
            imageUrls: Array<string>;
            isPublished: boolean;
            limitedScopeUrlHash: string;
            name: string;
            realTimeBooking: boolean;
            thumbnail?: string | null;
            address: {
                __typename?: 'Address';
                prefecture: {
                    __typename?: 'Prefecture';
                    name: string;
                    id: string;
                };
                town: {__typename?: 'Town'; name: string};
            };
            area?: {__typename?: 'Area'; name: string; id: string} | null;
            cuisines: Array<{__typename?: 'Cuisine'; id: string; name: string}>;
            priceRanges: Array<{
                __typename?: 'PriceRange';
                max: number;
                min: number;
                serviceType: ServiceType;
            }>;
        }>;
    } | null;
};

export type MarketingContentQueryVariables = Exact<{[key: string]: never}>;

export type MarketingContentQuery = {
    __typename?: 'Query';
    retrieveMarketingContent?: Array<{
        __typename?: 'MarketingContent';
        id: string;
        link: string;
        external: boolean;
        imageUrl: string;
        language: LanguageEnum;
        contentType: MarketingContentTypeEnum;
    }> | null;
};

export type VenueSearchResultFragment = {
    __typename?: 'Venue';
    blurb: string;
    id: string;
    imageUrls: Array<string>;
    isPublished: boolean;
    limitedScopeUrlHash: string;
    name: string;
    realTimeBooking: boolean;
    thumbnail?: string | null;
    address: {
        __typename?: 'Address';
        prefecture: {__typename?: 'Prefecture'; name: string; id: string};
        town: {__typename?: 'Town'; name: string};
    };
    area?: {__typename?: 'Area'; name: string; id: string} | null;
    cuisines: Array<{__typename?: 'Cuisine'; id: string; name: string}>;
    priceRanges: Array<{
        __typename?: 'PriceRange';
        max: number;
        min: number;
        serviceType: ServiceType;
    }>;
};

export type SearchVenuesQueryVariables = Exact<{
    areaIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
    cuisines?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
    date?: InputMaybe<Scalars['ISO8601Date']>;
    keyword?: InputMaybe<Scalars['String']>;
    partySize?: InputMaybe<Scalars['Int']>;
    maxPricePerPerson?: InputMaybe<Scalars['Int']>;
    minPricePerPerson?: InputMaybe<Scalars['Int']>;
    realTimeBooking?: InputMaybe<Scalars['Boolean']>;
    seatingTypes?: InputMaybe<Array<SeatingType> | SeatingType>;
    serviceTypes?: InputMaybe<Array<ServiceType> | ServiceType>;
    pagination?: InputMaybe<PaginationInput>;
}>;

export type SearchVenuesQuery = {
    __typename?: 'Query';
    venuesSearch?: {
        __typename?: 'VenueCollection';
        collection: Array<{
            __typename?: 'Venue';
            blurb: string;
            id: string;
            imageUrls: Array<string>;
            isPublished: boolean;
            limitedScopeUrlHash: string;
            name: string;
            realTimeBooking: boolean;
            thumbnail?: string | null;
            address: {
                __typename?: 'Address';
                prefecture: {
                    __typename?: 'Prefecture';
                    name: string;
                    id: string;
                };
                town: {__typename?: 'Town'; name: string};
            };
            area?: {__typename?: 'Area'; name: string; id: string} | null;
            cuisines: Array<{__typename?: 'Cuisine'; id: string; name: string}>;
            priceRanges: Array<{
                __typename?: 'PriceRange';
                max: number;
                min: number;
                serviceType: ServiceType;
            }>;
        }>;
        metadata: {
            __typename?: 'CollectionMetadata';
            currentPage: number;
            limitValue: number;
            totalCount: number;
            totalPages: number;
        };
    } | null;
};

export type VenueFragment = {
    __typename?: 'Venue';
    addressHidden: boolean;
    businessHours: string;
    blurb: string;
    googleMapUrl?: string | null;
    latitude?: number | null;
    longitude?: number | null;
    holidays: string;
    id: string;
    isPublished: boolean;
    imageUrls: Array<string>;
    limitedScopeUrlHash: string;
    localizedAddress: string;
    longDescription: string;
    name: string;
    nearestStations?: Array<string> | null;
    phoneNumber?: string | null;
    realTimeBooking: boolean;
    reservationTerms?: string | null;
    services: Array<string>;
    thumbnail?: string | null;
    websiteUrl?: string | null;
    transactionsAllowed: boolean;
    address: {
        __typename?: 'Address';
        prefecture: {__typename?: 'Prefecture'; name: string; id: string};
        town: {__typename?: 'Town'; name: string};
    };
    area?: {__typename?: 'Area'; name: string; id: string} | null;
    cuisines: Array<{__typename?: 'Cuisine'; id: string; name: string}>;
    frequentlyAskedQuestions: Array<{
        __typename?: 'FrequentlyAskedQuestion';
        answer: string;
        question: string;
    }>;
    priceRanges: Array<{
        __typename?: 'PriceRange';
        max: number;
        min: number;
        serviceType: ServiceType;
    }>;
    recommendations: Array<{
        __typename?: 'Recommendation';
        ageRange?: string | null;
        comment: string;
        visitFrequency?: VisitFrequency | null;
        visitPurpose?: VisitPurpose | null;
        visitedAt: string;
    }>;
    specialRequestOptions: Array<{
        __typename?: 'SpecialRequestOption';
        id: string;
        messageRequired: boolean;
        title: string;
    }>;
};

export type VenueWithCoursesFragment = {
    __typename?: 'Venue';
    addressHidden: boolean;
    businessHours: string;
    blurb: string;
    googleMapUrl?: string | null;
    latitude?: number | null;
    longitude?: number | null;
    holidays: string;
    id: string;
    isPublished: boolean;
    imageUrls: Array<string>;
    limitedScopeUrlHash: string;
    localizedAddress: string;
    longDescription: string;
    name: string;
    nearestStations?: Array<string> | null;
    phoneNumber?: string | null;
    realTimeBooking: boolean;
    reservationTerms?: string | null;
    services: Array<string>;
    thumbnail?: string | null;
    websiteUrl?: string | null;
    transactionsAllowed: boolean;
    courses: Array<{
        __typename?: 'Course';
        fixedPrice?: number | null;
        fixedTitle?: string | null;
        costPerGuest: number;
        id: string;
        name: string;
        serviceType: ServiceType;
        summary?: string | null;
        supplementaryInformation?: string | null;
    }>;
    address: {
        __typename?: 'Address';
        prefecture: {__typename?: 'Prefecture'; name: string; id: string};
        town: {__typename?: 'Town'; name: string};
    };
    area?: {__typename?: 'Area'; name: string; id: string} | null;
    cuisines: Array<{__typename?: 'Cuisine'; id: string; name: string}>;
    frequentlyAskedQuestions: Array<{
        __typename?: 'FrequentlyAskedQuestion';
        answer: string;
        question: string;
    }>;
    priceRanges: Array<{
        __typename?: 'PriceRange';
        max: number;
        min: number;
        serviceType: ServiceType;
    }>;
    recommendations: Array<{
        __typename?: 'Recommendation';
        ageRange?: string | null;
        comment: string;
        visitFrequency?: VisitFrequency | null;
        visitPurpose?: VisitPurpose | null;
        visitedAt: string;
    }>;
    specialRequestOptions: Array<{
        __typename?: 'SpecialRequestOption';
        id: string;
        messageRequired: boolean;
        title: string;
    }>;
};

export type VenuePageQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type VenuePageQuery = {
    __typename?: 'Query';
    venue: {
        __typename?: 'Venue';
        addressHidden: boolean;
        businessHours: string;
        blurb: string;
        googleMapUrl?: string | null;
        latitude?: number | null;
        longitude?: number | null;
        holidays: string;
        id: string;
        isPublished: boolean;
        imageUrls: Array<string>;
        limitedScopeUrlHash: string;
        localizedAddress: string;
        longDescription: string;
        name: string;
        nearestStations?: Array<string> | null;
        phoneNumber?: string | null;
        realTimeBooking: boolean;
        reservationTerms?: string | null;
        services: Array<string>;
        thumbnail?: string | null;
        websiteUrl?: string | null;
        transactionsAllowed: boolean;
        courses: Array<{
            __typename?: 'Course';
            fixedPrice?: number | null;
            fixedTitle?: string | null;
            costPerGuest: number;
            id: string;
            name: string;
            serviceType: ServiceType;
            summary?: string | null;
            supplementaryInformation?: string | null;
        }>;
        address: {
            __typename?: 'Address';
            prefecture: {__typename?: 'Prefecture'; name: string; id: string};
            town: {__typename?: 'Town'; name: string};
        };
        area?: {__typename?: 'Area'; name: string; id: string} | null;
        cuisines: Array<{__typename?: 'Cuisine'; id: string; name: string}>;
        frequentlyAskedQuestions: Array<{
            __typename?: 'FrequentlyAskedQuestion';
            answer: string;
            question: string;
        }>;
        priceRanges: Array<{
            __typename?: 'PriceRange';
            max: number;
            min: number;
            serviceType: ServiceType;
        }>;
        recommendations: Array<{
            __typename?: 'Recommendation';
            ageRange?: string | null;
            comment: string;
            visitFrequency?: VisitFrequency | null;
            visitPurpose?: VisitPurpose | null;
            visitedAt: string;
        }>;
        specialRequestOptions: Array<{
            __typename?: 'SpecialRequestOption';
            id: string;
            messageRequired: boolean;
            title: string;
        }>;
    };
};

export type UserFragment = {
    __typename?: 'User';
    birthday?: any | null;
    companyName?: string | null;
    featuredMailNotification: FeaturedMailNotificationEnum;
    email?: string | null;
    firstName?: string | null;
    firstNameKana?: string | null;
    gender?: Gender | null;
    id: string;
    lastName?: string | null;
    lastNameKana?: string | null;
    newsletter: boolean;
    bookmarkedVenues: Array<{
        __typename?: 'Venue';
        blurb: string;
        id: string;
        imageUrls: Array<string>;
        isPublished: boolean;
        limitedScopeUrlHash: string;
        name: string;
        realTimeBooking: boolean;
        thumbnail?: string | null;
        address: {
            __typename?: 'Address';
            prefecture: {__typename?: 'Prefecture'; name: string; id: string};
            town: {__typename?: 'Town'; name: string};
        };
        area?: {__typename?: 'Area'; name: string; id: string} | null;
        cuisines: Array<{__typename?: 'Cuisine'; id: string; name: string}>;
        priceRanges: Array<{
            __typename?: 'PriceRange';
            max: number;
            min: number;
            serviceType: ServiceType;
        }>;
    }>;
    phoneNumber?: {
        __typename?: 'PhoneNumber';
        countryCode?: number | null;
        e164Formatted: string;
        significant?: string | null;
    } | null;
};

export type AuthenticatedUserFragment = {
    __typename?: 'User';
    email?: string | null;
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    bookmarkedVenues: Array<{
        __typename?: 'Venue';
        blurb: string;
        id: string;
        imageUrls: Array<string>;
        isPublished: boolean;
        limitedScopeUrlHash: string;
        name: string;
        realTimeBooking: boolean;
        thumbnail?: string | null;
        address: {
            __typename?: 'Address';
            prefecture: {__typename?: 'Prefecture'; name: string; id: string};
            town: {__typename?: 'Town'; name: string};
        };
        area?: {__typename?: 'Area'; name: string; id: string} | null;
        cuisines: Array<{__typename?: 'Cuisine'; id: string; name: string}>;
        priceRanges: Array<{
            __typename?: 'PriceRange';
            max: number;
            min: number;
            serviceType: ServiceType;
        }>;
    }>;
};

export type UserQueryVariables = Exact<{[key: string]: never}>;

export type UserQuery = {
    __typename?: 'Query';
    user?: {
        __typename?: 'User';
        email?: string | null;
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        bookmarkedVenues: Array<{
            __typename?: 'Venue';
            blurb: string;
            id: string;
            imageUrls: Array<string>;
            isPublished: boolean;
            limitedScopeUrlHash: string;
            name: string;
            realTimeBooking: boolean;
            thumbnail?: string | null;
            address: {
                __typename?: 'Address';
                prefecture: {
                    __typename?: 'Prefecture';
                    name: string;
                    id: string;
                };
                town: {__typename?: 'Town'; name: string};
            };
            area?: {__typename?: 'Area'; name: string; id: string} | null;
            cuisines: Array<{__typename?: 'Cuisine'; id: string; name: string}>;
            priceRanges: Array<{
                __typename?: 'PriceRange';
                max: number;
                min: number;
                serviceType: ServiceType;
            }>;
        }>;
    } | null;
};

export const AvailabilityCalendarFragmentDoc = gql`
    fragment AvailabilityCalendar on Venue {
        availabilityCalendar {
            reservationDates
            waitlistDates
        }
    }
`;

export const ReserveUserProfileFragmentDoc = gql`
    fragment ReserveUserProfile on User {
        email
        firstName
        firstNameKana
        id
        lastName
        lastNameKana
        phoneNumber {
            countryCode
            significant
        }
    }
`;

export const ReserveVenueFragmentDoc = gql`
    fragment ReserveVenue on Venue {
        id
        name
        realTimeBooking
        reservationTerms
        specialRequestOptions {
            id
            messageRequired
            title
        }
    }
`;

export const UserProfileFragmentDoc = gql`
    fragment UserProfile on User {
        birthday
        companyName
        firstName
        firstNameKana
        gender
        id
        lastName
        lastNameKana
        newsletter
        featuredMailNotification
        phoneNumber {
            countryCode
            significant
        }
    }
`;

export const ReservationVenueFragmentDoc = gql`
    fragment ReservationVenue on Reservation {
        venue {
            name
            id
            imageUrls
            thumbnail
            realTimeBooking
            phoneNumber
            isPublished
            limitedScopeUrlHash
        }
    }
`;

export const ReservationWaitingListRequestFragmentDoc = gql`
    fragment ReservationWaitingListRequest on Reservation {
        waitingListRequest {
            dateTimeRangeEnd
            dateTimeRangeStart
            id
            deadline
        }
    }
`;

export const ReservationQuestionnaireFragmentDoc = gql`
    fragment ReservationQuestionnaire on Reservation {
        questionnaire {
            feedback
            satisfactionRating
        }
    }
`;

export const ReservationRyoshushoFragmentDoc = gql`
    fragment ReservationRyoshusho on Reservation {
        ryoshusho {
            url
        }
    }
`;

export const ReservationBaseFragmentDoc = gql`
    fragment ReservationBase on Reservation {
        cancellationPossible
        dateTime
        id
        numberOfPeople
        status
    }
`;

export const ReservationDetailFragmentDoc = gql`
    fragment ReservationDetail on Reservation {
        allergy
        course {
            id
            name
        }
        hotelGuestName
        hotelName
        id
        memo
        mandator {
            email
            firstName
            firstNameKana
            lastName
            lastNameKana
            phoneNumber {
                countryCode
                significant
            }
        }
        paymentMethod {
            ... on CreditCard {
                cardBrand
                cardExpiryMonth
                cardExpiryYear
                cardLast4
                id
            }
        }
        receipt {
            consumptionTax
            receiptItems {
                description
                quantity
                total
                unitPrice
            }
            total
        }
        ryoshusho {
            name
            url
        }
        seatingType
        specialRequestOption {
            id
            title
        }
        specialRequestOptionMessage
        visitFrequency
        visitPurpose
    }
`;

export const VenueFragmentDoc = gql`
    fragment Venue on Venue {
        addressHidden
        address {
            prefecture {
                name
                id
            }
            town {
                name
            }
        }
        area {
            name
            id
        }
        businessHours
        blurb
        cuisines {
            id
            name
        }
        frequentlyAskedQuestions {
            answer
            question
        }
        googleMapUrl
        latitude
        longitude
        holidays
        id
        isPublished
        imageUrls
        limitedScopeUrlHash
        localizedAddress
        longDescription
        name
        nearestStations
        phoneNumber
        priceRanges {
            max
            min
            serviceType
        }
        realTimeBooking
        recommendations {
            ageRange
            comment
            visitFrequency
            visitPurpose
            visitedAt
        }
        reservationTerms
        services
        specialRequestOptions {
            id
            messageRequired
            title
        }
        thumbnail
        websiteUrl
        transactionsAllowed
    }
`;

export const VenueWithCoursesFragmentDoc = gql`
    fragment VenueWithCourses on Venue {
        ...Venue
        courses {
            fixedPrice
            fixedTitle
            costPerGuest
            id
            name
            serviceType
            summary
            supplementaryInformation
        }
    }
    ${VenueFragmentDoc}
`;

export const VenueSearchResultFragmentDoc = gql`
    fragment VenueSearchResult on Venue {
        address {
            prefecture {
                name
                id
            }
            town {
                name
            }
        }
        area {
            name
            id
        }
        blurb
        cuisines {
            id
            name
        }
        id
        imageUrls
        isPublished
        limitedScopeUrlHash
        name
        priceRanges {
            max
            min
            serviceType
        }
        realTimeBooking
        thumbnail
    }
`;

export const UserFragmentDoc = gql`
    fragment User on User {
        birthday
        bookmarkedVenues {
            ...VenueSearchResult
        }
        companyName
        featuredMailNotification
        email
        firstName
        firstNameKana
        gender
        id
        lastName
        lastNameKana
        newsletter
        phoneNumber {
            countryCode
            e164Formatted
            significant
        }
    }
    ${VenueSearchResultFragmentDoc}
`;

export const AuthenticatedUserFragmentDoc = gql`
    fragment AuthenticatedUser on User {
        bookmarkedVenues {
            ...VenueSearchResult
        }
        email
        id
        firstName
        lastName
    }
    ${VenueSearchResultFragmentDoc}
`;

export const AddBookmarkDocument = gql`
    mutation addBookmark($venueId: ID!) {
        bookmarkAddVenue(input: {venueId: $venueId}) {
            clientMutationId
        }
    }
`;

export type AddBookmarkMutationFn = Apollo.MutationFunction<
    AddBookmarkMutation,
    AddBookmarkMutationVariables
>;

/**
 * __useAddBookmarkMutation__
 *
 * To run a mutation, you first call `useAddBookmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBookmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBookmarkMutation, { data, loading, error }] = useAddBookmarkMutation({
 *   variables: {
 *      venueId: // value for 'venueId'
 *   },
 * });
 */
export function useAddBookmarkMutation(
    baseOptions?: Apollo.MutationHookOptions<
        AddBookmarkMutation,
        AddBookmarkMutationVariables
    >
) {
    const options = {...defaultOptions, ...baseOptions};

    return Apollo.useMutation<
        AddBookmarkMutation,
        AddBookmarkMutationVariables
    >(AddBookmarkDocument, options);
}

export type AddBookmarkMutationHookResult = ReturnType<
    typeof useAddBookmarkMutation
>;

export type AddBookmarkMutationResult =
    Apollo.MutationResult<AddBookmarkMutation>;

export type AddBookmarkMutationOptions = Apollo.BaseMutationOptions<
    AddBookmarkMutation,
    AddBookmarkMutationVariables
>;

export const RemoveBookmarkDocument = gql`
    mutation removeBookmark($venueId: ID!) {
        bookmarkRemoveVenue(input: {venueId: $venueId}) {
            clientMutationId
        }
    }
`;

export type RemoveBookmarkMutationFn = Apollo.MutationFunction<
    RemoveBookmarkMutation,
    RemoveBookmarkMutationVariables
>;

/**
 * __useRemoveBookmarkMutation__
 *
 * To run a mutation, you first call `useRemoveBookmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveBookmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeBookmarkMutation, { data, loading, error }] = useRemoveBookmarkMutation({
 *   variables: {
 *      venueId: // value for 'venueId'
 *   },
 * });
 */
export function useRemoveBookmarkMutation(
    baseOptions?: Apollo.MutationHookOptions<
        RemoveBookmarkMutation,
        RemoveBookmarkMutationVariables
    >
) {
    const options = {...defaultOptions, ...baseOptions};

    return Apollo.useMutation<
        RemoveBookmarkMutation,
        RemoveBookmarkMutationVariables
    >(RemoveBookmarkDocument, options);
}

export type RemoveBookmarkMutationHookResult = ReturnType<
    typeof useRemoveBookmarkMutation
>;

export type RemoveBookmarkMutationResult =
    Apollo.MutationResult<RemoveBookmarkMutation>;

export type RemoveBookmarkMutationOptions = Apollo.BaseMutationOptions<
    RemoveBookmarkMutation,
    RemoveBookmarkMutationVariables
>;

export const LogoutDocument = gql`
    mutation logout {
        userLogout {
            authenticatable {
                id
            }
        }
    }
`;

export type LogoutMutationFn = Apollo.MutationFunction<
    LogoutMutation,
    LogoutMutationVariables
>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(
    baseOptions?: Apollo.MutationHookOptions<
        LogoutMutation,
        LogoutMutationVariables
    >
) {
    const options = {...defaultOptions, ...baseOptions};

    return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(
        LogoutDocument,
        options
    );
}

export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;

export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;

export type LogoutMutationOptions = Apollo.BaseMutationOptions<
    LogoutMutation,
    LogoutMutationVariables
>;

export const AvailabilitySearchDocument = gql`
    query AvailabilitySearch($venueId: ID!, $date: ISO8601Date!) {
        availabilitySearch(venueId: $venueId, date: $date) {
            ... on ReservableAvailability {
                id
                startTime
                endTime
                seatingType
                maxPartySize
                minPartySize
                course {
                    fixedPrice
                    fixedTitle
                    costPerGuest
                    id
                    name
                    serviceType
                    summary
                    supplementaryInformation
                }
            }
            ... on WaitlistableAvailability {
                startTime
                endTime
                maxPartySize
                minPartySize
                course {
                    fixedPrice
                    fixedTitle
                    costPerGuest
                    id
                    name
                    serviceType
                    summary
                    supplementaryInformation
                }
            }
        }
    }
`;

/**
 * __useAvailabilitySearchQuery__
 *
 * To run a query within a React component, call `useAvailabilitySearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailabilitySearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailabilitySearchQuery({
 *   variables: {
 *      venueId: // value for 'venueId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useAvailabilitySearchQuery(
    baseOptions: Apollo.QueryHookOptions<
        AvailabilitySearchQuery,
        AvailabilitySearchQueryVariables
    >
) {
    const options = {...defaultOptions, ...baseOptions};

    return Apollo.useQuery<
        AvailabilitySearchQuery,
        AvailabilitySearchQueryVariables
    >(AvailabilitySearchDocument, options);
}

export function useAvailabilitySearchLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        AvailabilitySearchQuery,
        AvailabilitySearchQueryVariables
    >
) {
    const options = {...defaultOptions, ...baseOptions};

    return Apollo.useLazyQuery<
        AvailabilitySearchQuery,
        AvailabilitySearchQueryVariables
    >(AvailabilitySearchDocument, options);
}

export type AvailabilitySearchQueryHookResult = ReturnType<
    typeof useAvailabilitySearchQuery
>;

export type AvailabilitySearchLazyQueryHookResult = ReturnType<
    typeof useAvailabilitySearchLazyQuery
>;

export type AvailabilitySearchQueryResult = Apollo.QueryResult<
    AvailabilitySearchQuery,
    AvailabilitySearchQueryVariables
>;

export const VenueAvailabilityCalendarDocument = gql`
    query VenueAvailabilityCalendar($id: ID!) {
        venue(id: $id) {
            ...AvailabilityCalendar
        }
    }
    ${AvailabilityCalendarFragmentDoc}
`;

/**
 * __useVenueAvailabilityCalendarQuery__
 *
 * To run a query within a React component, call `useVenueAvailabilityCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useVenueAvailabilityCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVenueAvailabilityCalendarQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVenueAvailabilityCalendarQuery(
    baseOptions: Apollo.QueryHookOptions<
        VenueAvailabilityCalendarQuery,
        VenueAvailabilityCalendarQueryVariables
    >
) {
    const options = {...defaultOptions, ...baseOptions};

    return Apollo.useQuery<
        VenueAvailabilityCalendarQuery,
        VenueAvailabilityCalendarQueryVariables
    >(VenueAvailabilityCalendarDocument, options);
}

export function useVenueAvailabilityCalendarLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        VenueAvailabilityCalendarQuery,
        VenueAvailabilityCalendarQueryVariables
    >
) {
    const options = {...defaultOptions, ...baseOptions};

    return Apollo.useLazyQuery<
        VenueAvailabilityCalendarQuery,
        VenueAvailabilityCalendarQueryVariables
    >(VenueAvailabilityCalendarDocument, options);
}

export type VenueAvailabilityCalendarQueryHookResult = ReturnType<
    typeof useVenueAvailabilityCalendarQuery
>;

export type VenueAvailabilityCalendarLazyQueryHookResult = ReturnType<
    typeof useVenueAvailabilityCalendarLazyQuery
>;

export type VenueAvailabilityCalendarQueryResult = Apollo.QueryResult<
    VenueAvailabilityCalendarQuery,
    VenueAvailabilityCalendarQueryVariables
>;

export const SecretAvailabilitySearchDocument = gql`
    query SecretAvailabilitySearch($venueId: ID!) {
        secretAvailabilitySearch(venueId: $venueId) {
            ... on ReservableAvailability {
                startTime
                endTime
                id
                seatingType
                maxPartySize
                minPartySize
                secretSeatExpiration
                course {
                    fixedPrice
                    fixedTitle
                    costPerGuest
                    id
                    name
                    serviceType
                    summary
                    supplementaryInformation
                }
            }
        }
    }
`;

/**
 * __useSecretAvailabilitySearchQuery__
 *
 * To run a query within a React component, call `useSecretAvailabilitySearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSecretAvailabilitySearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSecretAvailabilitySearchQuery({
 *   variables: {
 *      venueId: // value for 'venueId'
 *   },
 * });
 */
export function useSecretAvailabilitySearchQuery(
    baseOptions: Apollo.QueryHookOptions<
        SecretAvailabilitySearchQuery,
        SecretAvailabilitySearchQueryVariables
    >
) {
    const options = {...defaultOptions, ...baseOptions};

    return Apollo.useQuery<
        SecretAvailabilitySearchQuery,
        SecretAvailabilitySearchQueryVariables
    >(SecretAvailabilitySearchDocument, options);
}

export function useSecretAvailabilitySearchLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        SecretAvailabilitySearchQuery,
        SecretAvailabilitySearchQueryVariables
    >
) {
    const options = {...defaultOptions, ...baseOptions};

    return Apollo.useLazyQuery<
        SecretAvailabilitySearchQuery,
        SecretAvailabilitySearchQueryVariables
    >(SecretAvailabilitySearchDocument, options);
}

export type SecretAvailabilitySearchQueryHookResult = ReturnType<
    typeof useSecretAvailabilitySearchQuery
>;

export type SecretAvailabilitySearchLazyQueryHookResult = ReturnType<
    typeof useSecretAvailabilitySearchLazyQuery
>;

export type SecretAvailabilitySearchQueryResult = Apollo.QueryResult<
    SecretAvailabilitySearchQuery,
    SecretAvailabilitySearchQueryVariables
>;

export const CreateReservationDocument = gql`
    mutation createReservation($input: ReservationCreateInput!) {
        reservationCreate(input: $input) {
            clientMutationId
            reservation {
                id
            }
        }
    }
`;

export type CreateReservationMutationFn = Apollo.MutationFunction<
    CreateReservationMutation,
    CreateReservationMutationVariables
>;

/**
 * __useCreateReservationMutation__
 *
 * To run a mutation, you first call `useCreateReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReservationMutation, { data, loading, error }] = useCreateReservationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReservationMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateReservationMutation,
        CreateReservationMutationVariables
    >
) {
    const options = {...defaultOptions, ...baseOptions};

    return Apollo.useMutation<
        CreateReservationMutation,
        CreateReservationMutationVariables
    >(CreateReservationDocument, options);
}

export type CreateReservationMutationHookResult = ReturnType<
    typeof useCreateReservationMutation
>;

export type CreateReservationMutationResult =
    Apollo.MutationResult<CreateReservationMutation>;

export type CreateReservationMutationOptions = Apollo.BaseMutationOptions<
    CreateReservationMutation,
    CreateReservationMutationVariables
>;

export const ReserveDocument = gql`
    query reserve($venueId: ID!, $courseId: ID!) {
        coursesSearch(venueId: $venueId, courseId: $courseId) {
            fixedPrice
            fixedTitle
            costPerGuest
            id
            name
            serviceType
            summary
            supplementaryInformation
        }
        user {
            ...ReserveUserProfile
        }
        venue(id: $venueId) {
            ...ReserveVenue
        }
    }
    ${ReserveUserProfileFragmentDoc}
    ${ReserveVenueFragmentDoc}
`;

/**
 * __useReserveQuery__
 *
 * To run a query within a React component, call `useReserveQuery` and pass it any options that fit your needs.
 * When your component renders, `useReserveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReserveQuery({
 *   variables: {
 *      venueId: // value for 'venueId'
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useReserveQuery(
    baseOptions: Apollo.QueryHookOptions<ReserveQuery, ReserveQueryVariables>
) {
    const options = {...defaultOptions, ...baseOptions};

    return Apollo.useQuery<ReserveQuery, ReserveQueryVariables>(
        ReserveDocument,
        options
    );
}

export function useReserveLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ReserveQuery,
        ReserveQueryVariables
    >
) {
    const options = {...defaultOptions, ...baseOptions};

    return Apollo.useLazyQuery<ReserveQuery, ReserveQueryVariables>(
        ReserveDocument,
        options
    );
}

export type ReserveQueryHookResult = ReturnType<typeof useReserveQuery>;

export type ReserveLazyQueryHookResult = ReturnType<typeof useReserveLazyQuery>;

export type ReserveQueryResult = Apollo.QueryResult<
    ReserveQuery,
    ReserveQueryVariables
>;

export const AddCardDocument = gql`
    mutation addCard($stripeToken: String!, $isDefaultCard: Boolean) {
        addCard(
            input: {stripeToken: $stripeToken, defaultCard: $isDefaultCard}
        ) {
            creditCard {
                id
                stripeSetupIntentStatus
                confirmationNeeded
            }
            clientSecret
        }
    }
`;

export type AddCardMutationFn = Apollo.MutationFunction<
    AddCardMutation,
    AddCardMutationVariables
>;

/**
 * __useAddCardMutation__
 *
 * To run a mutation, you first call `useAddCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCardMutation, { data, loading, error }] = useAddCardMutation({
 *   variables: {
 *      stripeToken: // value for 'stripeToken'
 *      isDefaultCard: // value for 'isDefaultCard'
 *   },
 * });
 */
export function useAddCardMutation(
    baseOptions?: Apollo.MutationHookOptions<
        AddCardMutation,
        AddCardMutationVariables
    >
) {
    const options = {...defaultOptions, ...baseOptions};

    return Apollo.useMutation<AddCardMutation, AddCardMutationVariables>(
        AddCardDocument,
        options
    );
}

export type AddCardMutationHookResult = ReturnType<typeof useAddCardMutation>;

export type AddCardMutationResult = Apollo.MutationResult<AddCardMutation>;

export type AddCardMutationOptions = Apollo.BaseMutationOptions<
    AddCardMutation,
    AddCardMutationVariables
>;

export const SetDefaultCardDocument = gql`
    mutation setDefaultCard($id: ID!) {
        setDefaultCard(input: {id: $id}) {
            id
        }
    }
`;

export type SetDefaultCardMutationFn = Apollo.MutationFunction<
    SetDefaultCardMutation,
    SetDefaultCardMutationVariables
>;

/**
 * __useSetDefaultCardMutation__
 *
 * To run a mutation, you first call `useSetDefaultCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDefaultCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDefaultCardMutation, { data, loading, error }] = useSetDefaultCardMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSetDefaultCardMutation(
    baseOptions?: Apollo.MutationHookOptions<
        SetDefaultCardMutation,
        SetDefaultCardMutationVariables
    >
) {
    const options = {...defaultOptions, ...baseOptions};

    return Apollo.useMutation<
        SetDefaultCardMutation,
        SetDefaultCardMutationVariables
    >(SetDefaultCardDocument, options);
}

export type SetDefaultCardMutationHookResult = ReturnType<
    typeof useSetDefaultCardMutation
>;

export type SetDefaultCardMutationResult =
    Apollo.MutationResult<SetDefaultCardMutation>;

export type SetDefaultCardMutationOptions = Apollo.BaseMutationOptions<
    SetDefaultCardMutation,
    SetDefaultCardMutationVariables
>;

export const RequestSetupIntentDocument = gql`
    mutation requestSetupIntent($id: ID!) {
        requestSetupIntent(input: {id: $id}) {
            creditCard {
                stripeSetupIntentStatus
                confirmationNeeded
            }
            clientSecret
        }
    }
`;

export type RequestSetupIntentMutationFn = Apollo.MutationFunction<
    RequestSetupIntentMutation,
    RequestSetupIntentMutationVariables
>;

/**
 * __useRequestSetupIntentMutation__
 *
 * To run a mutation, you first call `useRequestSetupIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestSetupIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestSetupIntentMutation, { data, loading, error }] = useRequestSetupIntentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRequestSetupIntentMutation(
    baseOptions?: Apollo.MutationHookOptions<
        RequestSetupIntentMutation,
        RequestSetupIntentMutationVariables
    >
) {
    const options = {...defaultOptions, ...baseOptions};

    return Apollo.useMutation<
        RequestSetupIntentMutation,
        RequestSetupIntentMutationVariables
    >(RequestSetupIntentDocument, options);
}

export type RequestSetupIntentMutationHookResult = ReturnType<
    typeof useRequestSetupIntentMutation
>;

export type RequestSetupIntentMutationResult =
    Apollo.MutationResult<RequestSetupIntentMutation>;

export type RequestSetupIntentMutationOptions = Apollo.BaseMutationOptions<
    RequestSetupIntentMutation,
    RequestSetupIntentMutationVariables
>;

export const ConfirmSetupIntentDocument = gql`
    mutation confirmSetupIntent($id: ID!) {
        confirmSetupIntent(input: {id: $id}) {
            stripeSetupIntentStatus
        }
    }
`;

export type ConfirmSetupIntentMutationFn = Apollo.MutationFunction<
    ConfirmSetupIntentMutation,
    ConfirmSetupIntentMutationVariables
>;

/**
 * __useConfirmSetupIntentMutation__
 *
 * To run a mutation, you first call `useConfirmSetupIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmSetupIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmSetupIntentMutation, { data, loading, error }] = useConfirmSetupIntentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConfirmSetupIntentMutation(
    baseOptions?: Apollo.MutationHookOptions<
        ConfirmSetupIntentMutation,
        ConfirmSetupIntentMutationVariables
    >
) {
    const options = {...defaultOptions, ...baseOptions};

    return Apollo.useMutation<
        ConfirmSetupIntentMutation,
        ConfirmSetupIntentMutationVariables
    >(ConfirmSetupIntentDocument, options);
}

export type ConfirmSetupIntentMutationHookResult = ReturnType<
    typeof useConfirmSetupIntentMutation
>;

export type ConfirmSetupIntentMutationResult =
    Apollo.MutationResult<ConfirmSetupIntentMutation>;

export type ConfirmSetupIntentMutationOptions = Apollo.BaseMutationOptions<
    ConfirmSetupIntentMutation,
    ConfirmSetupIntentMutationVariables
>;

export const PaymentMethodRemoveDocument = gql`
    mutation paymentMethodRemove($id: ID!) {
        paymentMethodRemoveCreditCard(input: {id: $id}) {
            clientMutationId
        }
    }
`;

export type PaymentMethodRemoveMutationFn = Apollo.MutationFunction<
    PaymentMethodRemoveMutation,
    PaymentMethodRemoveMutationVariables
>;

/**
 * __usePaymentMethodRemoveMutation__
 *
 * To run a mutation, you first call `usePaymentMethodRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePaymentMethodRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [paymentMethodRemoveMutation, { data, loading, error }] = usePaymentMethodRemoveMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePaymentMethodRemoveMutation(
    baseOptions?: Apollo.MutationHookOptions<
        PaymentMethodRemoveMutation,
        PaymentMethodRemoveMutationVariables
    >
) {
    const options = {...defaultOptions, ...baseOptions};

    return Apollo.useMutation<
        PaymentMethodRemoveMutation,
        PaymentMethodRemoveMutationVariables
    >(PaymentMethodRemoveDocument, options);
}

export type PaymentMethodRemoveMutationHookResult = ReturnType<
    typeof usePaymentMethodRemoveMutation
>;

export type PaymentMethodRemoveMutationResult =
    Apollo.MutationResult<PaymentMethodRemoveMutation>;

export type PaymentMethodRemoveMutationOptions = Apollo.BaseMutationOptions<
    PaymentMethodRemoveMutation,
    PaymentMethodRemoveMutationVariables
>;

export const CreditCardSearchDocument = gql`
    query creditCardSearch($includeExpired: Boolean) {
        creditCardSearch(includeExpired: $includeExpired) {
            id
            cardBrand
            cardExpiryMonth
            cardExpiryYear
            cardLast4
            cardInUse
            confirmationNeeded
            defaultCard
            stripeToken
        }
    }
`;

/**
 * __useCreditCardSearchQuery__
 *
 * To run a query within a React component, call `useCreditCardSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditCardSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditCardSearchQuery({
 *   variables: {
 *      includeExpired: // value for 'includeExpired'
 *   },
 * });
 */
export function useCreditCardSearchQuery(
    baseOptions?: Apollo.QueryHookOptions<
        CreditCardSearchQuery,
        CreditCardSearchQueryVariables
    >
) {
    const options = {...defaultOptions, ...baseOptions};

    return Apollo.useQuery<
        CreditCardSearchQuery,
        CreditCardSearchQueryVariables
    >(CreditCardSearchDocument, options);
}

export function useCreditCardSearchLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        CreditCardSearchQuery,
        CreditCardSearchQueryVariables
    >
) {
    const options = {...defaultOptions, ...baseOptions};

    return Apollo.useLazyQuery<
        CreditCardSearchQuery,
        CreditCardSearchQueryVariables
    >(CreditCardSearchDocument, options);
}

export type CreditCardSearchQueryHookResult = ReturnType<
    typeof useCreditCardSearchQuery
>;

export type CreditCardSearchLazyQueryHookResult = ReturnType<
    typeof useCreditCardSearchLazyQuery
>;

export type CreditCardSearchQueryResult = Apollo.QueryResult<
    CreditCardSearchQuery,
    CreditCardSearchQueryVariables
>;

export const UserUpdateProfileDocument = gql`
    mutation userUpdateProfile(
        $birthday: ISO8601Date
        $companyName: String
        $firstName: String
        $firstNameKana: String
        $gender: Gender
        $lastName: String
        $lastNameKana: String
        $newsletter: Boolean!
        $phoneNumber: PhoneNumberInput
    ) {
        userUpdateProfile(
            input: {
                birthday: $birthday
                companyName: $companyName
                phoneNumber: $phoneNumber
                firstName: $firstName
                firstNameKana: $firstNameKana
                gender: $gender
                lastName: $lastName
                lastNameKana: $lastNameKana
                newsletter: $newsletter
            }
        ) {
            user {
                ...UserProfile
            }
            clientMutationId
        }
    }
    ${UserProfileFragmentDoc}
`;

export type UserUpdateProfileMutationFn = Apollo.MutationFunction<
    UserUpdateProfileMutation,
    UserUpdateProfileMutationVariables
>;

/**
 * __useUserUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUserUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdateProfileMutation, { data, loading, error }] = useUserUpdateProfileMutation({
 *   variables: {
 *      birthday: // value for 'birthday'
 *      companyName: // value for 'companyName'
 *      firstName: // value for 'firstName'
 *      firstNameKana: // value for 'firstNameKana'
 *      gender: // value for 'gender'
 *      lastName: // value for 'lastName'
 *      lastNameKana: // value for 'lastNameKana'
 *      newsletter: // value for 'newsletter'
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useUserUpdateProfileMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UserUpdateProfileMutation,
        UserUpdateProfileMutationVariables
    >
) {
    const options = {...defaultOptions, ...baseOptions};

    return Apollo.useMutation<
        UserUpdateProfileMutation,
        UserUpdateProfileMutationVariables
    >(UserUpdateProfileDocument, options);
}

export type UserUpdateProfileMutationHookResult = ReturnType<
    typeof useUserUpdateProfileMutation
>;

export type UserUpdateProfileMutationResult =
    Apollo.MutationResult<UserUpdateProfileMutation>;

export type UserUpdateProfileMutationOptions = Apollo.BaseMutationOptions<
    UserUpdateProfileMutation,
    UserUpdateProfileMutationVariables
>;

export const UpdateEmailPreferencesDocument = gql`
    mutation updateEmailPreferences(
        $newsletter: Boolean!
        $featuredMailNotification: FeaturedMailNotificationEnum
    ) {
        userUpdateProfile(
            input: {
                newsletter: $newsletter
                featuredMailNotification: $featuredMailNotification
            }
        ) {
            user {
                ...UserProfile
            }
            clientMutationId
        }
    }
    ${UserProfileFragmentDoc}
`;

export type UpdateEmailPreferencesMutationFn = Apollo.MutationFunction<
    UpdateEmailPreferencesMutation,
    UpdateEmailPreferencesMutationVariables
>;

/**
 * __useUpdateEmailPreferencesMutation__
 *
 * To run a mutation, you first call `useUpdateEmailPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmailPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmailPreferencesMutation, { data, loading, error }] = useUpdateEmailPreferencesMutation({
 *   variables: {
 *      newsletter: // value for 'newsletter'
 *      featuredMailNotification: // value for 'featuredMailNotification'
 *   },
 * });
 */
export function useUpdateEmailPreferencesMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateEmailPreferencesMutation,
        UpdateEmailPreferencesMutationVariables
    >
) {
    const options = {...defaultOptions, ...baseOptions};

    return Apollo.useMutation<
        UpdateEmailPreferencesMutation,
        UpdateEmailPreferencesMutationVariables
    >(UpdateEmailPreferencesDocument, options);
}

export type UpdateEmailPreferencesMutationHookResult = ReturnType<
    typeof useUpdateEmailPreferencesMutation
>;

export type UpdateEmailPreferencesMutationResult =
    Apollo.MutationResult<UpdateEmailPreferencesMutation>;

export type UpdateEmailPreferencesMutationOptions = Apollo.BaseMutationOptions<
    UpdateEmailPreferencesMutation,
    UpdateEmailPreferencesMutationVariables
>;

export const UserProfileDocument = gql`
    query userProfile {
        user {
            ...UserProfile
        }
    }
    ${UserProfileFragmentDoc}
`;

/**
 * __useUserProfileQuery__
 *
 * To run a query within a React component, call `useUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserProfileQuery(
    baseOptions?: Apollo.QueryHookOptions<
        UserProfileQuery,
        UserProfileQueryVariables
    >
) {
    const options = {...defaultOptions, ...baseOptions};

    return Apollo.useQuery<UserProfileQuery, UserProfileQueryVariables>(
        UserProfileDocument,
        options
    );
}

export function useUserProfileLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        UserProfileQuery,
        UserProfileQueryVariables
    >
) {
    const options = {...defaultOptions, ...baseOptions};

    return Apollo.useLazyQuery<UserProfileQuery, UserProfileQueryVariables>(
        UserProfileDocument,
        options
    );
}

export type UserProfileQueryHookResult = ReturnType<typeof useUserProfileQuery>;

export type UserProfileLazyQueryHookResult = ReturnType<
    typeof useUserProfileLazyQuery
>;

export type UserProfileQueryResult = Apollo.QueryResult<
    UserProfileQuery,
    UserProfileQueryVariables
>;

export const CancelReservationDocument = gql`
    mutation cancelReservation($id: ID!, $cancelReason: String) {
        reservationCancel(input: {id: $id, cancelReason: $cancelReason}) {
            clientMutationId
        }
    }
`;

export type CancelReservationMutationFn = Apollo.MutationFunction<
    CancelReservationMutation,
    CancelReservationMutationVariables
>;

/**
 * __useCancelReservationMutation__
 *
 * To run a mutation, you first call `useCancelReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelReservationMutation, { data, loading, error }] = useCancelReservationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      cancelReason: // value for 'cancelReason'
 *   },
 * });
 */
export function useCancelReservationMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CancelReservationMutation,
        CancelReservationMutationVariables
    >
) {
    const options = {...defaultOptions, ...baseOptions};

    return Apollo.useMutation<
        CancelReservationMutation,
        CancelReservationMutationVariables
    >(CancelReservationDocument, options);
}

export type CancelReservationMutationHookResult = ReturnType<
    typeof useCancelReservationMutation
>;

export type CancelReservationMutationResult =
    Apollo.MutationResult<CancelReservationMutation>;

export type CancelReservationMutationOptions = Apollo.BaseMutationOptions<
    CancelReservationMutation,
    CancelReservationMutationVariables
>;

export const ReservationCancelDocument = gql`
    query reservationCancel($id: ID!) {
        reservation(id: $id) {
            ...ReservationBase
            ...ReservationVenue
            ...ReservationWaitingListRequest
            cancellationInfo {
                amount
                percentage
            }
        }
    }
    ${ReservationBaseFragmentDoc}
    ${ReservationVenueFragmentDoc}
    ${ReservationWaitingListRequestFragmentDoc}
`;

/**
 * __useReservationCancelQuery__
 *
 * To run a query within a React component, call `useReservationCancelQuery` and pass it any options that fit your needs.
 * When your component renders, `useReservationCancelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReservationCancelQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReservationCancelQuery(
    baseOptions: Apollo.QueryHookOptions<
        ReservationCancelQuery,
        ReservationCancelQueryVariables
    >
) {
    const options = {...defaultOptions, ...baseOptions};

    return Apollo.useQuery<
        ReservationCancelQuery,
        ReservationCancelQueryVariables
    >(ReservationCancelDocument, options);
}

export function useReservationCancelLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ReservationCancelQuery,
        ReservationCancelQueryVariables
    >
) {
    const options = {...defaultOptions, ...baseOptions};

    return Apollo.useLazyQuery<
        ReservationCancelQuery,
        ReservationCancelQueryVariables
    >(ReservationCancelDocument, options);
}

export type ReservationCancelQueryHookResult = ReturnType<
    typeof useReservationCancelQuery
>;

export type ReservationCancelLazyQueryHookResult = ReturnType<
    typeof useReservationCancelLazyQuery
>;

export type ReservationCancelQueryResult = Apollo.QueryResult<
    ReservationCancelQuery,
    ReservationCancelQueryVariables
>;

export const SubmitQuestionnaireDocument = gql`
    mutation submitQuestionnaire(
        $id: ID!
        $satisfactionRating: QuestionnaireSatisfactionRating!
        $feedback: String!
    ) {
        reservationSubmitQuestionnaire(
            input: {
                id: $id
                satisfactionRating: $satisfactionRating
                feedback: $feedback
            }
        ) {
            clientMutationId
        }
    }
`;

export type SubmitQuestionnaireMutationFn = Apollo.MutationFunction<
    SubmitQuestionnaireMutation,
    SubmitQuestionnaireMutationVariables
>;

/**
 * __useSubmitQuestionnaireMutation__
 *
 * To run a mutation, you first call `useSubmitQuestionnaireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitQuestionnaireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitQuestionnaireMutation, { data, loading, error }] = useSubmitQuestionnaireMutation({
 *   variables: {
 *      id: // value for 'id'
 *      satisfactionRating: // value for 'satisfactionRating'
 *      feedback: // value for 'feedback'
 *   },
 * });
 */
export function useSubmitQuestionnaireMutation(
    baseOptions?: Apollo.MutationHookOptions<
        SubmitQuestionnaireMutation,
        SubmitQuestionnaireMutationVariables
    >
) {
    const options = {...defaultOptions, ...baseOptions};

    return Apollo.useMutation<
        SubmitQuestionnaireMutation,
        SubmitQuestionnaireMutationVariables
    >(SubmitQuestionnaireDocument, options);
}

export type SubmitQuestionnaireMutationHookResult = ReturnType<
    typeof useSubmitQuestionnaireMutation
>;

export type SubmitQuestionnaireMutationResult =
    Apollo.MutationResult<SubmitQuestionnaireMutation>;

export type SubmitQuestionnaireMutationOptions = Apollo.BaseMutationOptions<
    SubmitQuestionnaireMutation,
    SubmitQuestionnaireMutationVariables
>;

export const SendMessageDocument = gql`
    mutation sendMessage($id: ID!, $message: String!) {
        reservationSendMessage(input: {id: $id, message: $message}) {
            clientMutationId
        }
    }
`;

export type SendMessageMutationFn = Apollo.MutationFunction<
    SendMessageMutation,
    SendMessageMutationVariables
>;

/**
 * __useSendMessageMutation__
 *
 * To run a mutation, you first call `useSendMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessageMutation, { data, loading, error }] = useSendMessageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useSendMessageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        SendMessageMutation,
        SendMessageMutationVariables
    >
) {
    const options = {...defaultOptions, ...baseOptions};

    return Apollo.useMutation<
        SendMessageMutation,
        SendMessageMutationVariables
    >(SendMessageDocument, options);
}

export type SendMessageMutationHookResult = ReturnType<
    typeof useSendMessageMutation
>;

export type SendMessageMutationResult =
    Apollo.MutationResult<SendMessageMutation>;

export type SendMessageMutationOptions = Apollo.BaseMutationOptions<
    SendMessageMutation,
    SendMessageMutationVariables
>;

export const ReservationMessageDocument = gql`
    query reservationMessage($id: ID!) {
        reservation(id: $id) {
            ...ReservationBase
            ...ReservationVenue
            ...ReservationWaitingListRequest
        }
    }
    ${ReservationBaseFragmentDoc}
    ${ReservationVenueFragmentDoc}
    ${ReservationWaitingListRequestFragmentDoc}
`;

/**
 * __useReservationMessageQuery__
 *
 * To run a query within a React component, call `useReservationMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useReservationMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReservationMessageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReservationMessageQuery(
    baseOptions: Apollo.QueryHookOptions<
        ReservationMessageQuery,
        ReservationMessageQueryVariables
    >
) {
    const options = {...defaultOptions, ...baseOptions};

    return Apollo.useQuery<
        ReservationMessageQuery,
        ReservationMessageQueryVariables
    >(ReservationMessageDocument, options);
}

export function useReservationMessageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ReservationMessageQuery,
        ReservationMessageQueryVariables
    >
) {
    const options = {...defaultOptions, ...baseOptions};

    return Apollo.useLazyQuery<
        ReservationMessageQuery,
        ReservationMessageQueryVariables
    >(ReservationMessageDocument, options);
}

export type ReservationMessageQueryHookResult = ReturnType<
    typeof useReservationMessageQuery
>;

export type ReservationMessageLazyQueryHookResult = ReturnType<
    typeof useReservationMessageLazyQuery
>;

export type ReservationMessageQueryResult = Apollo.QueryResult<
    ReservationMessageQuery,
    ReservationMessageQueryVariables
>;

export const ReservationsDocument = gql`
    query reservations {
        user {
            ...ReserveUserProfile
            reservations {
                ...ReservationBase
                ...ReservationVenue
                ...ReservationWaitingListRequest
                ...ReservationQuestionnaire
                ...ReservationRyoshusho
            }
        }
    }
    ${ReserveUserProfileFragmentDoc}
    ${ReservationBaseFragmentDoc}
    ${ReservationVenueFragmentDoc}
    ${ReservationWaitingListRequestFragmentDoc}
    ${ReservationQuestionnaireFragmentDoc}
    ${ReservationRyoshushoFragmentDoc}
`;

/**
 * __useReservationsQuery__
 *
 * To run a query within a React component, call `useReservationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReservationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReservationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useReservationsQuery(
    baseOptions?: Apollo.QueryHookOptions<
        ReservationsQuery,
        ReservationsQueryVariables
    >
) {
    const options = {...defaultOptions, ...baseOptions};

    return Apollo.useQuery<ReservationsQuery, ReservationsQueryVariables>(
        ReservationsDocument,
        options
    );
}

export function useReservationsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ReservationsQuery,
        ReservationsQueryVariables
    >
) {
    const options = {...defaultOptions, ...baseOptions};

    return Apollo.useLazyQuery<ReservationsQuery, ReservationsQueryVariables>(
        ReservationsDocument,
        options
    );
}

export type ReservationsQueryHookResult = ReturnType<
    typeof useReservationsQuery
>;

export type ReservationsLazyQueryHookResult = ReturnType<
    typeof useReservationsLazyQuery
>;

export type ReservationsQueryResult = Apollo.QueryResult<
    ReservationsQuery,
    ReservationsQueryVariables
>;

export const ReservationDetailDocument = gql`
    query reservationDetail($id: ID!) {
        reservation(id: $id) {
            ...ReservationDetail
        }
    }
    ${ReservationDetailFragmentDoc}
`;

/**
 * __useReservationDetailQuery__
 *
 * To run a query within a React component, call `useReservationDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useReservationDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReservationDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReservationDetailQuery(
    baseOptions: Apollo.QueryHookOptions<
        ReservationDetailQuery,
        ReservationDetailQueryVariables
    >
) {
    const options = {...defaultOptions, ...baseOptions};

    return Apollo.useQuery<
        ReservationDetailQuery,
        ReservationDetailQueryVariables
    >(ReservationDetailDocument, options);
}

export function useReservationDetailLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ReservationDetailQuery,
        ReservationDetailQueryVariables
    >
) {
    const options = {...defaultOptions, ...baseOptions};

    return Apollo.useLazyQuery<
        ReservationDetailQuery,
        ReservationDetailQueryVariables
    >(ReservationDetailDocument, options);
}

export type ReservationDetailQueryHookResult = ReturnType<
    typeof useReservationDetailQuery
>;

export type ReservationDetailLazyQueryHookResult = ReturnType<
    typeof useReservationDetailLazyQuery
>;

export type ReservationDetailQueryResult = Apollo.QueryResult<
    ReservationDetailQuery,
    ReservationDetailQueryVariables
>;

export const UpdateEmailDocument = gql`
    mutation updateEmail($email: String!) {
        userUpdateEmail(input: {email: $email}) {
            confirmationEmailSent
        }
    }
`;

export type UpdateEmailMutationFn = Apollo.MutationFunction<
    UpdateEmailMutation,
    UpdateEmailMutationVariables
>;

/**
 * __useUpdateEmailMutation__
 *
 * To run a mutation, you first call `useUpdateEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmailMutation, { data, loading, error }] = useUpdateEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUpdateEmailMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateEmailMutation,
        UpdateEmailMutationVariables
    >
) {
    const options = {...defaultOptions, ...baseOptions};

    return Apollo.useMutation<
        UpdateEmailMutation,
        UpdateEmailMutationVariables
    >(UpdateEmailDocument, options);
}

export type UpdateEmailMutationHookResult = ReturnType<
    typeof useUpdateEmailMutation
>;

export type UpdateEmailMutationResult =
    Apollo.MutationResult<UpdateEmailMutation>;

export type UpdateEmailMutationOptions = Apollo.BaseMutationOptions<
    UpdateEmailMutation,
    UpdateEmailMutationVariables
>;

export const UpdatePasswordDocument = gql`
    mutation updatePassword($currentPassword: String!, $newPassword: String!) {
        userUpdatePassword(
            input: {
                currentPassword: $currentPassword
                newPassword: $newPassword
            }
        ) {
            clientMutationId
        }
    }
`;

export type UpdatePasswordMutationFn = Apollo.MutationFunction<
    UpdatePasswordMutation,
    UpdatePasswordMutationVariables
>;

/**
 * __useUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordMutation, { data, loading, error }] = useUpdatePasswordMutation({
 *   variables: {
 *      currentPassword: // value for 'currentPassword'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useUpdatePasswordMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdatePasswordMutation,
        UpdatePasswordMutationVariables
    >
) {
    const options = {...defaultOptions, ...baseOptions};

    return Apollo.useMutation<
        UpdatePasswordMutation,
        UpdatePasswordMutationVariables
    >(UpdatePasswordDocument, options);
}

export type UpdatePasswordMutationHookResult = ReturnType<
    typeof useUpdatePasswordMutation
>;

export type UpdatePasswordMutationResult =
    Apollo.MutationResult<UpdatePasswordMutation>;

export type UpdatePasswordMutationOptions = Apollo.BaseMutationOptions<
    UpdatePasswordMutation,
    UpdatePasswordMutationVariables
>;

export const DeleteAccountDocument = gql`
    mutation deleteAccount(
        $note: String!
        $reasons: [AccountDeletionReason!]!
    ) {
        userDeleteAccount(input: {note: $note, reasons: $reasons}) {
            clientMutationId
        }
    }
`;

export type DeleteAccountMutationFn = Apollo.MutationFunction<
    DeleteAccountMutation,
    DeleteAccountMutationVariables
>;

/**
 * __useDeleteAccountMutation__
 *
 * To run a mutation, you first call `useDeleteAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountMutation, { data, loading, error }] = useDeleteAccountMutation({
 *   variables: {
 *      note: // value for 'note'
 *      reasons: // value for 'reasons'
 *   },
 * });
 */
export function useDeleteAccountMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteAccountMutation,
        DeleteAccountMutationVariables
    >
) {
    const options = {...defaultOptions, ...baseOptions};

    return Apollo.useMutation<
        DeleteAccountMutation,
        DeleteAccountMutationVariables
    >(DeleteAccountDocument, options);
}

export type DeleteAccountMutationHookResult = ReturnType<
    typeof useDeleteAccountMutation
>;

export type DeleteAccountMutationResult =
    Apollo.MutationResult<DeleteAccountMutation>;

export type DeleteAccountMutationOptions = Apollo.BaseMutationOptions<
    DeleteAccountMutation,
    DeleteAccountMutationVariables
>;

export const LoginDocument = gql`
    mutation login($email: String!, $password: String!) {
        userLogin(email: $email, password: $password) {
            credentials {
                accessToken
                uid
                tokenType
                expiry
                client
            }
        }
    }
`;

export type LoginMutationFn = Apollo.MutationFunction<
    LoginMutation,
    LoginMutationVariables
>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(
    baseOptions?: Apollo.MutationHookOptions<
        LoginMutation,
        LoginMutationVariables
    >
) {
    const options = {...defaultOptions, ...baseOptions};

    return Apollo.useMutation<LoginMutation, LoginMutationVariables>(
        LoginDocument,
        options
    );
}

export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;

export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;

export type LoginMutationOptions = Apollo.BaseMutationOptions<
    LoginMutation,
    LoginMutationVariables
>;

export const SignupDocument = gql`
    mutation signup(
        $birthday: ISO8601Date!
        $email: String!
        $firstName: String!
        $firstNameKana: String
        $lastName: String!
        $lastNameKana: String
        $newsletter: Boolean!
        $password: String!
    ) {
        userSignUp(
            input: {
                birthday: $birthday
                email: $email
                firstName: $firstName
                firstNameKana: $firstNameKana
                lastName: $lastName
                lastNameKana: $lastNameKana
                newsletter: $newsletter
                password: $password
            }
        ) {
            clientMutationId
        }
    }
`;

export type SignupMutationFn = Apollo.MutationFunction<
    SignupMutation,
    SignupMutationVariables
>;

/**
 * __useSignupMutation__
 *
 * To run a mutation, you first call `useSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupMutation, { data, loading, error }] = useSignupMutation({
 *   variables: {
 *      birthday: // value for 'birthday'
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      firstNameKana: // value for 'firstNameKana'
 *      lastName: // value for 'lastName'
 *      lastNameKana: // value for 'lastNameKana'
 *      newsletter: // value for 'newsletter'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSignupMutation(
    baseOptions?: Apollo.MutationHookOptions<
        SignupMutation,
        SignupMutationVariables
    >
) {
    const options = {...defaultOptions, ...baseOptions};

    return Apollo.useMutation<SignupMutation, SignupMutationVariables>(
        SignupDocument,
        options
    );
}

export type SignupMutationHookResult = ReturnType<typeof useSignupMutation>;

export type SignupMutationResult = Apollo.MutationResult<SignupMutation>;

export type SignupMutationOptions = Apollo.BaseMutationOptions<
    SignupMutation,
    SignupMutationVariables
>;

export const ForgotPasswordDocument = gql`
    mutation forgotPassword($email: String!, $redirectUrl: String!) {
        userSendPasswordResetWithToken(
            email: $email
            redirectUrl: $redirectUrl
        ) {
            message
        }
    }
`;

export type ForgotPasswordMutationFn = Apollo.MutationFunction<
    ForgotPasswordMutation,
    ForgotPasswordMutationVariables
>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *      redirectUrl: // value for 'redirectUrl'
 *   },
 * });
 */
export function useForgotPasswordMutation(
    baseOptions?: Apollo.MutationHookOptions<
        ForgotPasswordMutation,
        ForgotPasswordMutationVariables
    >
) {
    const options = {...defaultOptions, ...baseOptions};

    return Apollo.useMutation<
        ForgotPasswordMutation,
        ForgotPasswordMutationVariables
    >(ForgotPasswordDocument, options);
}

export type ForgotPasswordMutationHookResult = ReturnType<
    typeof useForgotPasswordMutation
>;

export type ForgotPasswordMutationResult =
    Apollo.MutationResult<ForgotPasswordMutation>;

export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<
    ForgotPasswordMutation,
    ForgotPasswordMutationVariables
>;

export const ResendConfirmationDocument = gql`
    mutation resendConfirmation($email: String!, $confirmUrl: String!) {
        userResendConfirmationWithToken(
            email: $email
            confirmUrl: $confirmUrl
        ) {
            message
        }
    }
`;

export type ResendConfirmationMutationFn = Apollo.MutationFunction<
    ResendConfirmationMutation,
    ResendConfirmationMutationVariables
>;

/**
 * __useResendConfirmationMutation__
 *
 * To run a mutation, you first call `useResendConfirmationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendConfirmationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendConfirmationMutation, { data, loading, error }] = useResendConfirmationMutation({
 *   variables: {
 *      email: // value for 'email'
 *      confirmUrl: // value for 'confirmUrl'
 *   },
 * });
 */
export function useResendConfirmationMutation(
    baseOptions?: Apollo.MutationHookOptions<
        ResendConfirmationMutation,
        ResendConfirmationMutationVariables
    >
) {
    const options = {...defaultOptions, ...baseOptions};

    return Apollo.useMutation<
        ResendConfirmationMutation,
        ResendConfirmationMutationVariables
    >(ResendConfirmationDocument, options);
}

export type ResendConfirmationMutationHookResult = ReturnType<
    typeof useResendConfirmationMutation
>;

export type ResendConfirmationMutationResult =
    Apollo.MutationResult<ResendConfirmationMutation>;

export type ResendConfirmationMutationOptions = Apollo.BaseMutationOptions<
    ResendConfirmationMutation,
    ResendConfirmationMutationVariables
>;

export const ResetPasswordDocument = gql`
    mutation resetPassword(
        $password: String!
        $passwordConfirmation: String!
        $token: String!
    ) {
        userUpdatePasswordWithToken(
            input: {
                password: $password
                passwordConfirmation: $passwordConfirmation
                token: $token
            }
        ) {
            clientMutationId
        }
    }
`;

export type ResetPasswordMutationFn = Apollo.MutationFunction<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      passwordConfirmation: // value for 'passwordConfirmation'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useResetPasswordMutation(
    baseOptions?: Apollo.MutationHookOptions<
        ResetPasswordMutation,
        ResetPasswordMutationVariables
    >
) {
    const options = {...defaultOptions, ...baseOptions};

    return Apollo.useMutation<
        ResetPasswordMutation,
        ResetPasswordMutationVariables
    >(ResetPasswordDocument, options);
}

export type ResetPasswordMutationHookResult = ReturnType<
    typeof useResetPasswordMutation
>;

export type ResetPasswordMutationResult =
    Apollo.MutationResult<ResetPasswordMutation>;

export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
>;

export const SignupConfirmationDocument = gql`
    mutation signupConfirmation($token: String!) {
        userConfirmRegistrationWithToken(confirmationToken: $token) {
            authenticatable {
                id
            }
            credentials {
                accessToken
                client
                expiry
                tokenType
                uid
            }
        }
    }
`;

export type SignupConfirmationMutationFn = Apollo.MutationFunction<
    SignupConfirmationMutation,
    SignupConfirmationMutationVariables
>;

/**
 * __useSignupConfirmationMutation__
 *
 * To run a mutation, you first call `useSignupConfirmationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupConfirmationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupConfirmationMutation, { data, loading, error }] = useSignupConfirmationMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useSignupConfirmationMutation(
    baseOptions?: Apollo.MutationHookOptions<
        SignupConfirmationMutation,
        SignupConfirmationMutationVariables
    >
) {
    const options = {...defaultOptions, ...baseOptions};

    return Apollo.useMutation<
        SignupConfirmationMutation,
        SignupConfirmationMutationVariables
    >(SignupConfirmationDocument, options);
}

export type SignupConfirmationMutationHookResult = ReturnType<
    typeof useSignupConfirmationMutation
>;

export type SignupConfirmationMutationResult =
    Apollo.MutationResult<SignupConfirmationMutation>;

export type SignupConfirmationMutationOptions = Apollo.BaseMutationOptions<
    SignupConfirmationMutation,
    SignupConfirmationMutationVariables
>;

export const UnlockAccountDocument = gql`
    mutation unlockAccount($token: String!) {
        userUnlockAccount(input: {token: $token}) {
            clientMutationId
        }
    }
`;

export type UnlockAccountMutationFn = Apollo.MutationFunction<
    UnlockAccountMutation,
    UnlockAccountMutationVariables
>;

/**
 * __useUnlockAccountMutation__
 *
 * To run a mutation, you first call `useUnlockAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlockAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlockAccountMutation, { data, loading, error }] = useUnlockAccountMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useUnlockAccountMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UnlockAccountMutation,
        UnlockAccountMutationVariables
    >
) {
    const options = {...defaultOptions, ...baseOptions};

    return Apollo.useMutation<
        UnlockAccountMutation,
        UnlockAccountMutationVariables
    >(UnlockAccountDocument, options);
}

export type UnlockAccountMutationHookResult = ReturnType<
    typeof useUnlockAccountMutation
>;

export type UnlockAccountMutationResult =
    Apollo.MutationResult<UnlockAccountMutation>;

export type UnlockAccountMutationOptions = Apollo.BaseMutationOptions<
    UnlockAccountMutation,
    UnlockAccountMutationVariables
>;

export const SearchPropertiesDocument = gql`
    query SearchProperties {
        areas {
            id
            name
        }
        cuisines {
            id
            name
        }
    }
`;

/**
 * __useSearchPropertiesQuery__
 *
 * To run a query within a React component, call `useSearchPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPropertiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSearchPropertiesQuery(
    baseOptions?: Apollo.QueryHookOptions<
        SearchPropertiesQuery,
        SearchPropertiesQueryVariables
    >
) {
    const options = {...defaultOptions, ...baseOptions};

    return Apollo.useQuery<
        SearchPropertiesQuery,
        SearchPropertiesQueryVariables
    >(SearchPropertiesDocument, options);
}

export function useSearchPropertiesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        SearchPropertiesQuery,
        SearchPropertiesQueryVariables
    >
) {
    const options = {...defaultOptions, ...baseOptions};

    return Apollo.useLazyQuery<
        SearchPropertiesQuery,
        SearchPropertiesQueryVariables
    >(SearchPropertiesDocument, options);
}

export type SearchPropertiesQueryHookResult = ReturnType<
    typeof useSearchPropertiesQuery
>;

export type SearchPropertiesLazyQueryHookResult = ReturnType<
    typeof useSearchPropertiesLazyQuery
>;

export type SearchPropertiesQueryResult = Apollo.QueryResult<
    SearchPropertiesQuery,
    SearchPropertiesQueryVariables
>;

export const HomePageDocument = gql`
    query homePage($featuredVenueIds: [ID!]) {
        newArrivals: venuesSearch(
            orderBy: CREATION_DESC
            paginationInput: {limit: 4}
        ) {
            collection {
                ...VenueSearchResult
            }
        }
        featured: venuesSearch(ids: $featuredVenueIds) {
            collection {
                ...VenueSearchResult
            }
        }
        pickUp: venuesSearch(ids: ["245459", "245461", "244966", "245273"]) {
            collection {
                ...VenueSearchResult
            }
        }
    }
    ${VenueSearchResultFragmentDoc}
`;

/**
 * __useHomePageQuery__
 *
 * To run a query within a React component, call `useHomePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomePageQuery({
 *   variables: {
 *      featuredVenueIds: // value for 'featuredVenueIds'
 *   },
 * });
 */
export function useHomePageQuery(
    baseOptions?: Apollo.QueryHookOptions<HomePageQuery, HomePageQueryVariables>
) {
    const options = {...defaultOptions, ...baseOptions};

    return Apollo.useQuery<HomePageQuery, HomePageQueryVariables>(
        HomePageDocument,
        options
    );
}

export function useHomePageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        HomePageQuery,
        HomePageQueryVariables
    >
) {
    const options = {...defaultOptions, ...baseOptions};

    return Apollo.useLazyQuery<HomePageQuery, HomePageQueryVariables>(
        HomePageDocument,
        options
    );
}

export type HomePageQueryHookResult = ReturnType<typeof useHomePageQuery>;

export type HomePageLazyQueryHookResult = ReturnType<
    typeof useHomePageLazyQuery
>;

export type HomePageQueryResult = Apollo.QueryResult<
    HomePageQuery,
    HomePageQueryVariables
>;

export const MarketingContentDocument = gql`
    query MarketingContent {
        retrieveMarketingContent {
            id
            link
            external
            imageUrl
            language
            contentType
        }
    }
`;

/**
 * __useMarketingContentQuery__
 *
 * To run a query within a React component, call `useMarketingContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketingContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketingContentQuery({
 *   variables: {
 *   },
 * });
 */
export function useMarketingContentQuery(
    baseOptions?: Apollo.QueryHookOptions<
        MarketingContentQuery,
        MarketingContentQueryVariables
    >
) {
    const options = {...defaultOptions, ...baseOptions};

    return Apollo.useQuery<
        MarketingContentQuery,
        MarketingContentQueryVariables
    >(MarketingContentDocument, options);
}

export function useMarketingContentLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        MarketingContentQuery,
        MarketingContentQueryVariables
    >
) {
    const options = {...defaultOptions, ...baseOptions};

    return Apollo.useLazyQuery<
        MarketingContentQuery,
        MarketingContentQueryVariables
    >(MarketingContentDocument, options);
}

export type MarketingContentQueryHookResult = ReturnType<
    typeof useMarketingContentQuery
>;

export type MarketingContentLazyQueryHookResult = ReturnType<
    typeof useMarketingContentLazyQuery
>;

export type MarketingContentQueryResult = Apollo.QueryResult<
    MarketingContentQuery,
    MarketingContentQueryVariables
>;

export const SearchVenuesDocument = gql`
    query searchVenues(
        $areaIds: [ID!]
        $cuisines: [ID!]
        $date: ISO8601Date
        $keyword: String
        $partySize: Int
        $maxPricePerPerson: Int
        $minPricePerPerson: Int
        $realTimeBooking: Boolean
        $seatingTypes: [SeatingType!]
        $serviceTypes: [ServiceType!]
        $pagination: PaginationInput
    ) {
        venuesSearch(
            paginationInput: $pagination
            areaIds: $areaIds
            cuisines: $cuisines
            date: $date
            keyword: $keyword
            partySize: $partySize
            minPricePerPerson: $minPricePerPerson
            maxPricePerPerson: $maxPricePerPerson
            realTimeBooking: $realTimeBooking
            seatingTypes: $seatingTypes
            serviceTypes: $serviceTypes
        ) {
            collection {
                ...VenueSearchResult
            }
            metadata {
                currentPage
                limitValue
                totalCount
                totalPages
            }
        }
    }
    ${VenueSearchResultFragmentDoc}
`;

/**
 * __useSearchVenuesQuery__
 *
 * To run a query within a React component, call `useSearchVenuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchVenuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchVenuesQuery({
 *   variables: {
 *      areaIds: // value for 'areaIds'
 *      cuisines: // value for 'cuisines'
 *      date: // value for 'date'
 *      keyword: // value for 'keyword'
 *      partySize: // value for 'partySize'
 *      maxPricePerPerson: // value for 'maxPricePerPerson'
 *      minPricePerPerson: // value for 'minPricePerPerson'
 *      realTimeBooking: // value for 'realTimeBooking'
 *      seatingTypes: // value for 'seatingTypes'
 *      serviceTypes: // value for 'serviceTypes'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useSearchVenuesQuery(
    baseOptions?: Apollo.QueryHookOptions<
        SearchVenuesQuery,
        SearchVenuesQueryVariables
    >
) {
    const options = {...defaultOptions, ...baseOptions};

    return Apollo.useQuery<SearchVenuesQuery, SearchVenuesQueryVariables>(
        SearchVenuesDocument,
        options
    );
}

export function useSearchVenuesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        SearchVenuesQuery,
        SearchVenuesQueryVariables
    >
) {
    const options = {...defaultOptions, ...baseOptions};

    return Apollo.useLazyQuery<SearchVenuesQuery, SearchVenuesQueryVariables>(
        SearchVenuesDocument,
        options
    );
}

export type SearchVenuesQueryHookResult = ReturnType<
    typeof useSearchVenuesQuery
>;

export type SearchVenuesLazyQueryHookResult = ReturnType<
    typeof useSearchVenuesLazyQuery
>;

export type SearchVenuesQueryResult = Apollo.QueryResult<
    SearchVenuesQuery,
    SearchVenuesQueryVariables
>;

export const VenuePageDocument = gql`
    query VenuePage($id: ID!) {
        venue(id: $id) {
            ...VenueWithCourses
        }
    }
    ${VenueWithCoursesFragmentDoc}
`;

/**
 * __useVenuePageQuery__
 *
 * To run a query within a React component, call `useVenuePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useVenuePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVenuePageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVenuePageQuery(
    baseOptions: Apollo.QueryHookOptions<
        VenuePageQuery,
        VenuePageQueryVariables
    >
) {
    const options = {...defaultOptions, ...baseOptions};

    return Apollo.useQuery<VenuePageQuery, VenuePageQueryVariables>(
        VenuePageDocument,
        options
    );
}

export function useVenuePageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        VenuePageQuery,
        VenuePageQueryVariables
    >
) {
    const options = {...defaultOptions, ...baseOptions};

    return Apollo.useLazyQuery<VenuePageQuery, VenuePageQueryVariables>(
        VenuePageDocument,
        options
    );
}

export type VenuePageQueryHookResult = ReturnType<typeof useVenuePageQuery>;

export type VenuePageLazyQueryHookResult = ReturnType<
    typeof useVenuePageLazyQuery
>;

export type VenuePageQueryResult = Apollo.QueryResult<
    VenuePageQuery,
    VenuePageQueryVariables
>;

export const UserDocument = gql`
    query user {
        user {
            ...AuthenticatedUser
        }
    }
    ${AuthenticatedUserFragmentDoc}
`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserQuery(
    baseOptions?: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>
) {
    const options = {...defaultOptions, ...baseOptions};

    return Apollo.useQuery<UserQuery, UserQueryVariables>(
        UserDocument,
        options
    );
}

export function useUserLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>
) {
    const options = {...defaultOptions, ...baseOptions};

    return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(
        UserDocument,
        options
    );
}

export type UserQueryHookResult = ReturnType<typeof useUserQuery>;

export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;

export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
