import {Trans, useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Spinner from 'components/Loaders/Spinner';
import Modal from 'components/Modal';
import NoResults from 'components/NoResults';
import Authenticate from 'components/User/Authentication';
import {format} from 'date-fns';
import {ja} from 'date-fns/locale';
import {useBookmarks} from 'hooks/useBookmarks';
import {useLocale} from 'state/locale';
import {toFullDate, toJST} from 'utils/date';

type Props = {
    currentDate: Date;
    nextAvailableDates: string[];
    setDate: (date: Date) => void;
    venueId: string;
    venueName: string;
};

export const NoAvailability = ({
    currentDate,
    nextAvailableDates,
    setDate,
    venueId,
    venueName,
}: Props) => {
    const locale = useLocale();
    const {t} = useTranslation();

    const {isBookmarked, isLoading, onClick, onClose, showAuthModal} =
        useBookmarks(venueId);

    if (nextAvailableDates.length > 0) {
        return (
            <NoResults
                className="mx-auto w-[94%] md:py-8 md:text-sm"
                delay={250}
                icon="dates"
                label={
                    <Trans
                        components={{
                            br: <br />,
                        }}
                        i18nKey="venue.tabs.courses.noCoursesAvailable"
                        values={{
                            date: toFullDate(currentDate, locale),
                        }}
                    />
                }
            >
                <div className="flex flex-col gap-y-2 place-self-start pt-4">
                    <span className="text-lg text-grey-500 dark:text-grey-300">
                        {t('venue.nextAvailableDates')}
                    </span>
                    <div className="flex flex-row gap-x-2">
                        {nextAvailableDates.map((dateString) => {
                            const newDate = toJST(new Date(dateString));

                            return (
                                <button
                                    key={dateString}
                                    aria-label={dateString}
                                    className="bg-body flex items-center justify-center rounded border border-gold-200 bg-gold-200 p-2 text-gold-200 hover:bg-gold-200 hover:text-white focus-visible:ring-1 dark:text-gold-200 dark:hover:text-grey-900"
                                    data-dd-action-name="NextAvailableDate"
                                    onClick={() => setDate(newDate)}
                                    type="button"
                                >
                                    {locale === 'en'
                                        ? format(newDate, 'PPP')
                                        : format(newDate, 'PPP（E）', {
                                              locale: ja,
                                          })}
                                </button>
                            );
                        })}
                    </div>
                </div>
            </NoResults>
        );
    }

    return (
        <div className="flex flex-col items-center py-4 text-center text-grey-500 dark:text-grey-300">
            <span className="mb-2 text-lg">
                {t('venue.noAvailabilitiesTitle')}
            </span>
            <span className="mb-4 text-base">
                {t('venue.noAvailabilities')}
            </span>
            <button
                aria-label={t('bookmarks.addVenue', {name: venueName})}
                className={
                    isLoading
                        ? 'h-9 w-9 rounded-full'
                        : 'flex items-center justify-center gap-x-2 rounded px-4 py-2 text-gold-200 hover:text-gold-100 focus-visible:ring-1 active:text-gold-300'
                }
                data-dd-action-name="BookmarkVenueReservationWidget"
                onClick={onClick}
                type="button"
            >
                {isLoading ? (
                    <Spinner
                        color="step"
                        size="lg"
                        title={
                            isBookmarked
                                ? t('bookmarks.remove')
                                : t('bookmarks.add')
                        }
                    />
                ) : (
                    <>
                        <FontAwesomeIcon
                            icon={[isBookmarked ? 'fas' : 'far', 'bookmark']}
                        />
                        {isBookmarked
                            ? t('bookmarks.remove')
                            : t('bookmarks.add')}
                    </>
                )}
            </button>
            {showAuthModal && (
                <Modal onClose={onClose}>
                    <Authenticate
                        login={true}
                        message={t('authentication.toBookmark')}
                        onClose={onClose}
                    />
                </Modal>
            )}
        </div>
    );
};
